import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { timeRangeEnum, timeRangeConfig } from 'shared/DatePicker/constants';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import {
  AddWidgetModal,
  CSVModal,
  Button,
  EditWidgetModal,
  Page,
  PageContent,
  PageHeader,
  WidgetGrid,
  ContentLoader,
} from 'shared';
import AddDashboardModal from 'industry/components/AddDashboardModal';
import * as containerActions from 'industry/IndustryLocationContainer/actions';
import * as appActions from 'app/actions';
import * as actions from './actions';
import ExportIndicator from './components/ExportIndicator';

import './styles.scss';

class IndustryLocationDashboard extends Component {
  constructor() {
    super();

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      showAddModal: false,
      showCSVModal: false,
      showEditModal: false,
      showEditDashboardModal: false,
      exportInProgress: false,
      currentExportMetric: null,
      totalExportMetrics: null,
      finishingExport: false,
      isReadOnly,
      orders: [],
      isLoadingOrders: true,
      isLoadingDateRange: false,
      globalFilters: {
        order: null,
      },
      query: '',
    };

    this.timeout = null;
  }

  componentDidMount() {
    const {
      match: {
        params: {
          dashboardId,
          companyId,
          locationId,
        },
      },
    } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.props.fetchDashboard({ dashboardId });
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {
          companyId,
          droneId,
          locationId,
          dashboardId,
        },
      },
      meta: {
        daterange: {
          label,
          start,
          end,
        },
      },
      widgets,
    } = this.props;

    if (prevProps.match.params.companyId !== companyId) {
      this.props.fetchDashboard({ companyId, locationId, droneId, dashboardId });
    }
    if (prevProps.meta.daterange.label !== label) {
      this.props.patchDashboardRangeChange({ dashboardId, label });
    }
    if (
      !prevProps.meta.daterange.start.isSame(start) ||
      !prevProps.meta.daterange.end.isSame(end) ||
      prevProps.meta.daterange.label !== label ||
      JSON.stringify(this.extractUniqueAssetIds(prevProps.widgets)) !== JSON.stringify(this.extractUniqueAssetIds(widgets))
    ) {
      this.fetchProductionDataOrders();
    }
  }

  onChangeGlobalFilters = (field, value) => {
    const { globalFilters } = this.state;

    this.setState({
      globalFilters: {
        ...globalFilters,
        [field]: value,
      },
    });
  }

  onInputChange = (input) => {
    if (input.length >= 3) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.setState({ query: input }, () => {
          this.searchOrderByQuery();
        });
      }, 500);
    } else if (!input.length && this.state.query.length !== 0) {
      this.setState({ query: '' }, () => {
        this.fetchProductionDataOrders();
      });
    }
  };

  onSelectChange = (o, triggeredAction) => {
    if (triggeredAction && triggeredAction.action && triggeredAction.action === 'clear') {
      this.clearGlobalFilters();
    } else {
      this.getOrderRange(o.id);
    }
  }

  getOrderRange = (order_id) => {
    this.setState({
      query: '',
      isLoadingDateRange: true,
    });

    actions.getOrderRange(order_id)
      .then((re) => {
        if (re && re.data && re.data.from && re.data.to) {
          this.setState({
            isLoadingDateRange: false,
          }, () => {
            this.props.setDateRange({
              start: moment(re.data.from),
              end: moment(re.data.to),
              label: 'custom',
            });
            this.onChangeGlobalFilters('order', order_id);
          });
        }
      });
  }

  extractUniqueAssetIds(arrayOfObjects) {
    if (arrayOfObjects && arrayOfObjects.length) {
      const assetIdsSet = new Set();

      arrayOfObjects.forEach((object) => {
        if (
          object &&
          object.widgetData &&
          object.widgetData.length > 0 &&
          object.widgetData[0].metric &&
          object.widgetData[0].metric.asset
        ) {
          assetIdsSet.add(object.widgetData[0].metric.asset.id ? object.widgetData[0].metric.asset.id : object.widgetData[0].metric.asset);
        }
      });
      return Array.from(assetIdsSet);
    }
    return '';
  }

  searchOrderByQuery = () => {
    const {
      match: {
        params: {
          companyId,
          locationId,
        },
      },
    } = this.props;

    const { query } = this.state;

    this.setState({
      isLoadingOrders: true,
    });

    let filters = '';
    filters = `&external_id=${query}`;

    actions.getOrders(companyId, locationId, filters)
      .then((re) => {
        this.setState({
          orders: re.data.results || [],
          isLoadingOrders: false,
        });
      });
  }

  fetchProductionDataOrders = () => {
    const {
      widgets,
      match: {
        params: { companyId, locationId },
      },
      meta: {
        daterange,
      },
    } = this.props;

    let to = daterange.end;
    let from = daterange.start;
    const label = daterange.label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      to = timeRangeConfig()[label].endDate;
      from = timeRangeConfig()[label].startDate;
    }

    this.setState({
      isLoadingOrders: true,
    });

    let filters = `&from=${moment(from).utc().toISOString()}&to=${moment(to).utc().toISOString()}`;

    if (widgets && widgets.length) {
      filters += `&asset_in=${this.extractUniqueAssetIds(widgets)}`;
    }

    actions.getProductionDataOrders(companyId, locationId, filters)
      .then((re) => {
        this.setState({
          orders: re && re.data && re.data.data ? re.data.data : [],
          isLoadingOrders: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingOrders: false,
        });
      });
  };

  handleAddWidget = (type) => {
    const {
      id: dashboardId,
      layouts,
    } = this.props;

    this.props.createWidget({ type, dashboardId, layouts })
      .then((x) => {
        this.setState({
          showEditModal: true,
          selectedWidgetForEdit: x.id,
        });
      })
      .catch((e) => console.error(e));
  }

  handleLayoutChange = (layouts) => {
    const { id: dashboardId } = this.props;
    if (!dashboardId) {
      return;
    }
    this.props.setLayouts({ dashboardId, layouts });
  }

  handleWidgetSave = (config) => {
    const {
      match: {
        params: {
          dashboardId,
        },
      },
    } = this.props;

    config.dashboard = dashboardId;
    this.props.updateWidget(config).then(() => {
      this.setState({ showEditModal: false });
    });
  }

  handleEditWidget = (id) => {
    this.setState({
      showEditModal: true,
      selectedWidgetForEdit: id,
    });
  }

  handleDashboardUpdate = (data) => {
    // const {
    //   match: {
    //     params: {
    //       locationId,
    //     },
    //   },
    // } = this.props;
    this.props.patchDashboard(data);
    this.props.addOrUpdateDashboardSuccess(data);
  }

  convertDashboardToImage = () => {
    const resizableHandles = document.getElementsByClassName('react-resizable-handle');
    for (const handle of resizableHandles) {
      handle.style.display = 'none';
    }
    const printable = document.getElementsByClassName('printable');
    if (printable && printable.length) {
      printable[0].remove();
    }
    const dashboardRaw = document.getElementById('dashboard-wrapper');
    const div = document.createElement('div');
    div.className = 'printable';

    html2canvas(dashboardRaw, {
      scale: 2,
    }).then((canvas) => {
      const pageHeight = 295;

      const ratio = canvas.height / canvas.width;

      // eslint-disable-next-line
      const pdf = new jsPDF({
        unit: 'mm',
      });

      let position = 0;

      const width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      height = ratio * width;
      let heightLeft = height;

      const { title } = this.props;

      pdf.addImage(canvas, 'PNG', 5, 10, width - 5, height);
      pdf.setFont('helvetica');
      pdf.text(12, 10, title);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = (heightLeft - height) + 9;
        pdf.addPage();
        pdf.addImage(canvas, 'PNG', 5, position, width - 5, height);
        heightLeft -= pageHeight;
      }

      pdf.save(`${title}.pdf`);

      this.setState({
        exportInProgress: false,
        currentExportMetric: null,
        totalExportMetrics: null,
        finishingExport: false,
      }, () => {
        for (const handle of resizableHandles) {
          handle.style.display = 'inline';
        }
      });
    }).catch((e) => console.log(e));
  }

  exportDashboard = () => {
    this.setState({
      exportInProgress: true,
      finishingExport: true,
    });
    this.convertDashboardToImage();
  }

  clearGlobalFilters = () => {
    this.setState({
      globalFilters: {
        order: null,
      },
      query: '',
    });
  }

  render() {
    const {
      layouts,
      widgets,
      drone,
      meta,
      title,
      isLoading,
      match: {
        params: {
          dashboardId,
          locationId,
          companyId,
        },
      },
      updateManualRefresh,
      currentCompany,
      currentLocation,
      manualRefresh,
      setDateRange,
      t,
      currentUser,
      isLocked,
    } = this.props;

    const {
      showAddModal,
      showEditModal,
      showCSVModal,
      showEditDashboardModal,
      selectedWidgetForEdit,
      exportInProgress,
      currentExportMetric,
      totalExportMetrics,
      finishingExport,
      isReadOnly,
      isLoadingOrders,
      isLoadingDateRange,
      orders,
      globalFilters,
      globalFilters: {
        order,
      },
      query,
    } = this.state;

    if (isLoading) {
      return (
        <Page>
          <PageHeader />
          <PageContent>
            <div className="loaderWrapper">
              <ContentLoader />
            </div>;
          </PageContent>
        </Page>
      );
    }

    return (
      <Page classes={['fullscreen']}>
        <PageHeader>
          <div className="left">
            <h1>
              {title}
            </h1>
          </div>
          <div className="right">
            {
              exportInProgress ? <ExportIndicator current={currentExportMetric} total={totalExportMetrics} finishing={finishingExport} /> : ''
            }
            {
              <Fragment>
                <Button
                  onClick={this.exportDashboard}
                  type="export"
                >
                  {t('page_content.dashboards.export_pdf_button')}
                </Button>
                <Button
                  onClick={() => this.setState({ showCSVModal: true })}
                  type="export"
                >
                  {t('page_content.dashboards.export_csv_button')}
                </Button>
                <Button
                  onClick={() => this.setState({ showAddModal: true })}
                  disabled={isLocked && currentUser && currentUser.is_staff === false ? true : isReadOnly}
                  type="add"
                >
                  {t('page_content.dashboards.add_widget_button')}
                </Button>
                <Button
                  disabled={isLocked && currentUser && currentUser.is_staff === false ? true : isReadOnly}
                  onClick={() => this.setState({ showEditDashboardModal: true })}
                >
                  {t('page_content.dashboards.edit_dashboard_button')}
                </Button>
              </Fragment>
            }
          </div>
        </PageHeader>
        <div className="global_filters">
          <p>{t('page_content.dashboards.order_filter')}</p>
          <div style={{ display: 'inline-block', position: 'relative', marginLeft: '10px' }}>
            <Select
              isClearable
              options={isLoadingOrders ? [] : orders}
              getOptionLabel={(option) => option.external_id}
              getOptionValue={(option) => option.id}
              isSearchable
              placeholder={t('page_content.dashboards.external_id_input_placeholder')}
              onChange={(o, triggeredAction) => this.onSelectChange(o, triggeredAction)}
              onInputChange={this.onInputChange}
              value={query || (orders.length ? orders.find((o) => o.external_id === order) : '')}
              styles={selectStyles}
              isLoading={isLoadingOrders || isLoadingDateRange}
              loadingMessage={() => <ContentLoader />}
            />
          </div>
        </div>
        <PageContent>
          <div className="drone-dashboard-container" id="dashboard-wrapper">
            <WidgetGrid
              isLocked={isLocked && currentUser && currentUser.is_staff === false ? true : isReadOnly}
              layouts={layouts}
              widgetMeta={meta}
              companyId={companyId}
              dashboardId={dashboardId}
              locationId={locationId}
              onLayoutChange={this.handleLayoutChange}
              widgets={widgets}
              onRemoveWidget={this.props.deleteWidget}
              onCopyWidget={this.props.copyWidget}
              onEditWidget={this.handleEditWidget}
              onBreakpointChange={() => { }}
              updateManualRefresh={updateManualRefresh}
              manualRefresh={manualRefresh}
              setDateRange={setDateRange}
              globalFilters={globalFilters}
            />
          </div>
        </PageContent>
        {
          showAddModal &&
          <AddWidgetModal
            onAddWidget={this.handleAddWidget}
            handleRequestClose={() => this.setState({ showAddModal: false })}
            isOpen
            companyId={companyId}
          />
        }
        {
          showCSVModal &&
          <CSVModal
            handleRequestClose={() => this.setState({ showCSVModal: false })}
            isOpen
            widgetsData={{ widgets, widgetsMeta: meta }}
          />
        }
        {
          showEditModal &&
          <EditWidgetModal
            isDroneChangeable={false}
            onSave={this.handleWidgetSave}
            handleRequestClose={() => this.setState({ showEditModal: false })}
            isOpen
            drone={drone}
            widgetId={selectedWidgetForEdit}
            dashboardId={dashboardId}
            locationId={locationId}
            company={companyId}
          />
        }
        {
          showEditDashboardModal &&
          <AddDashboardModal
            addOrUpdateDashboardSuccess={this.handleDashboardUpdate}
            id={dashboardId}
            locationId={currentLocation.id}
            onClose={() => this.setState({ showEditDashboardModal: false })}
            postDeleteUrl={`/${currentCompany.id}/industry/location/${currentLocation.id}/dashboards/`}
          />
        }
      </Page>
    );
  }
}

IndustryLocationDashboard.propTypes = {
  deleteWidget: PropTypes.func.isRequired,
  copyWidget: PropTypes.func.isRequired,
  updateManualRefresh: PropTypes.func,
  currentCompany: PropTypes.any,
  currentLocation: PropTypes.any,
  manualRefresh: PropTypes.any,
  setDateRange: PropTypes.func,
  widgets: PropTypes.array,
  drone: PropTypes.any,
  meta: PropTypes.object,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  patchDashboard: PropTypes.func,
  addOrUpdateDashboardSuccess: PropTypes.func,
  setLayouts: PropTypes.func,
  updateWidget: PropTypes.func,
  match: PropTypes.object,
  fetchDashboard: PropTypes.func,
  patchDashboardRangeChange: PropTypes.func,
  id: PropTypes.number,
  createWidget: PropTypes.func,
  layouts: PropTypes.object,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  isLocked: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    ...state.industry.dashboard,
    meta: {
      interval: state.app.interval,
      daterange: state.app.daterange,
    },
    currentCompany: state.app.company,
    currentLocation: state.app.location,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  showAddWidget: actions.showAddWidget,
  deleteWidget: actions.deleteWidget,
  copyWidget: actions.copyWidget,
  setLayouts: actions.setLayouts,
  updateWidget: actions.editWidget,
  fetchDashboard: actions.fetchDashboard,
  createWidget: actions.createWidget,
  patchDashboardRangeChange: actions.patchDashboardRangeChange,
  updateManualRefresh: actions.updateManualRefresh,
  patchDashboard: actions.patchDashboard,
  addOrUpdateDashboardSuccess: containerActions.addOrUpdateDashboardSuccess,
  setDateRange: appActions.setDateRange,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationDashboard)));
