/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';

class OrderBookingsEditToBookModal extends Component {
  constructor() {
    super();
    this.state = {
      quantity_total_booked: null,
    };
  }

    onChange = (value) => {
      this.setState({
        quantity_total_booked: value,
      });
    }

    onSaveBookingsToBookModal = (quantity_total_booked) => {
      const { onSaveBookingsToBookModal } = this.props;

      onSaveBookingsToBookModal(quantity_total_booked);

      this.setState({
        quantity_total_booked: null,
      });
    }

    getNumberOfDecimals = (num) => {
      if (Number.isInteger(num)) {
        return 0;
      }

      const decimalStr = num.toString().split('.')[1];
      return decimalStr ? decimalStr.length : 0;
    }

    truncateDecimals = (num, digits) => {
      const numS = num.toString();
      const decPos = numS.indexOf('.');
      // eslint-disable-next-line eqeqeq
      const substrLength = decPos == -1 ? numS.length : 1 + decPos + digits;
      const trimmedResult = numS.substr(0, substrLength);
      const finalResult = Number.isNaN(trimmedResult) ? 0 : trimmedResult;

      return parseFloat(finalResult);
    }

    closeModal = () => {
      const { closeEditToBookModal } = this.props;

      this.setState({
        calculated_remaining: null,
        quantity_booked: null,
      }, closeEditToBookModal());
    }

    render() {
      const { quantity_total_booked } = this.state;

      const {
        t,
        isEditToBookModalOpen,
        selectedBookingItem,
      } = this.props;

      return (
        <Modal
          isOpen={isEditToBookModalOpen}
          handleSave={() => this.onSaveBookingsToBookModal(quantity_total_booked)}
          handleClose={this.closeModal}
          title={selectedBookingItem && selectedBookingItem.order_item && selectedBookingItem.order_item.name ? selectedBookingItem.order_item.name : ''}
        >
          <div className="default-form">
            <table>
              <tbody>
                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.bookings_tab.table_column_quantity_to_book')}
                  </td>
                  <td className="value">
                    <input
                      type="number"
                      value={quantity_total_booked !== null ? quantity_total_booked.toString() : (selectedBookingItem && selectedBookingItem.quantity_total_booked ?
                        this.truncateDecimals(selectedBookingItem.quantity_total_booked, 2) : null)}
                      onChange={(e) => {
                        // max two decimals in input
                        const numberOfDecimals = this.getNumberOfDecimals(e.target.value);
                        if (numberOfDecimals < 4) {
                          this.onChange(e.target.value);
                        }
                      }}
                    />
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </Modal>
      );
    }
}

OrderBookingsEditToBookModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeEditToBookModal: PropTypes.func.isRequired,
  onSaveBookingsToBookModal: PropTypes.func.isRequired,
  isEditToBookModalOpen: PropTypes.bool.isRequired,
  selectedBookingItem: PropTypes.object,
};

export default withTranslation()(OrderBookingsEditToBookModal);
