import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { IconRemove } from 'shared/Icons';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { deleteCompanyValue, getCompanyValues, getPaginatedCompanyValues } from '../../../actions';
import '../styles.scss';
import CompanyValuesModal from './CompanyValuesModal';

const CompanyValuesTable = ({ t, companyId }) => {
  const [companyValues, setCompanyValues] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [companyValuesFilters, setCompanyValuesFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchCompanyValues = () => {
    setCompanyValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = companyValuesFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${companyValuesFilters?.selectedSort}`;

    getCompanyValues(companyId, apiFilters)
      .then((res) => {
        setCompanyValues({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setCompanyValues((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedCompanyValues = (url) => {
    setCompanyValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedCompanyValues(url)
      .then((res) => {
        setCompanyValues({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setCompanyValues((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchCompanyValues();
  }, [companyId, companyValuesFilters]);

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteCompanyValue(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchCompanyValues();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setCompanyValuesFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewCompanyValue = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchCompanyValues();
  };

  return (
    <React.Fragment>
      <div className="company_values_table">
        <h3>{t('page_content.human_resources.control_panel.company_values.company_values')}</h3>

        <div className="table_action_buttons">
        <Button
          type="add"
          onClick={handleAddNewCompanyValue}
        >
          {t('page_content.human_resources.control_panel.company_values.add_new_company_value')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
            },
          ]}
          data={companyValues.data || []}
          defaultPageSize={100}
          loading={companyValues.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          handleClick={(rowInfo) => handleTableRowClick(get(rowInfo, 'original'))}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={companyValues.previous}
              next={companyValues.next}
              fetchFunction={fetchPaginatedCompanyValues}
              count={companyValues.count}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <CompanyValuesModal
        t={t}
        isOpen={modalData?.isOpen}
        initialValues={modalData?.selectedItem}
        handleCloseModal={handleCloseModal}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        itemName={`${get(deleteData, 'original.name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </React.Fragment>
  );
};

CompanyValuesTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default withTranslation()(CompanyValuesTable);
