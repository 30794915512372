/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { PieChart as RePieChart, Tooltip, Pie, Cell, Legend } from 'recharts';
import {
  renderLegend,
} from '../helpers';
import WidgetPlaceholder from '../../WidgetPlaceholder';

const colorIndex = [
  '#396ab1',
  '#da7c30',
  '#3e9651',
  '#cc2529',
  '#535154',
  '#6b4c9a',
  '#922428',
  '#8f8b3d',
];

const RADIAN = Math.PI / 180;
// eslint-disable-next-line
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = 5 + innerRadius + (outerRadius - innerRadius) * 1;
  const x = cx + (radius * Math.cos(-midAngle * RADIAN));
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      fontSize={10}
      fontWeight={400}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class PieChart extends Component {
  prepareData(data = {}, widgetData = []) {
    const newData = [];
    const filteredWidgetData = widgetData.filter((wD) => !wD.style.isCalculatedValue);
    Object.keys(data).forEach((key) => {
      const currentWidgetData = filteredWidgetData.find((x) => String(x.id) === key);
      let valueKey = 'value';

      if (currentWidgetData) {
        const {
          style: {
            selectedField,
          },
        } = currentWidgetData;

        valueKey = selectedField || 'value';
      }
      if (!data[key]) {
        return;
      }
      if (!currentWidgetData) {
        return;
      }
      const value = data[key][valueKey];
      newData.push({
        id: key,
        name: currentWidgetData && currentWidgetData.label ? currentWidgetData.label : '',
        value,
      });
    });

    return newData;
  }

  render() {
    const {
      height,
      data,
      settings,
      width,
      widgetData,
    } = this.props;

    // if (!!widgetData.length && !Object.keys(data).length) {

    // }

    if (!settings || !get(widgetData, 'length')) {
      return <WidgetPlaceholder height={this.props.height} type="pie" />;
    }

    const graphData = this.prepareData(data, widgetData);

    if (settings && settings.axis) {
      const axis = settings.axis;
      if (axis.y && axis.y.format === 'float') {
        if (widgetData && widgetData[0]) {
          const key = widgetData[0].id;
          const decimalPlaces = settings.axis.y.decimal_places ? settings.axis.y.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      } else if (axis.y1 && axis.y1.format === 'float') {
        if (widgetData && widgetData[0]) {
          const key = widgetData[0].id;
          const decimalPlaces = settings.axis.y1.decimal_places ? settings.axis.y1.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      } else if (axis.y2 && axis.y2.format === 'float') {
        if (widgetData && widgetData[1]) {
          const key = widgetData[1].id;
          const decimalPlaces = settings.axis.y2.decimal_places ? settings.axis.y2.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      } else if (axis.y3 && axis.y3.format === 'float') {
        if (widgetData && widgetData[2]) {
          const key = widgetData[2].id;
          const decimalPlaces = settings.axis.y3.decimal_places ? settings.axis.y3.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      }
    }

    return (
      <RePieChart
        width={width}
        height={height}
        // data={graphData}
        margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
      >
        <Pie
          isAnimationActive={false}
          data={graphData}
          labelLine={false}
          innerRadius={10}
          outerRadius={40}
          cx="50%"
          cy="50%"
          label={renderCustomizedLabel}
        >
          {
            graphData.map((entry, index) => <Cell key={`pie-cell-${index}`} fill={(settings.pieColors && settings.pieColors[entry.id]) ? settings.pieColors[entry.id].hex : colorIndex[index]} />)
          }
        </Pie>
        <Tooltip />
        {
          (settings && settings.legend && settings.legend.show) &&
          <Legend
            verticalAlign="bottom"
            height={25}
            content={renderLegend}
          />
        }
      </RePieChart>
    );
  }
}

PieChart.defaultProps = {
  data: {},
  widgetData: [],
};

PieChart.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  widgetData: PropTypes.array,
  settings: PropTypes.object,
};

export default PieChart;
