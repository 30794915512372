import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ScoringCategories from './components/ScoringCategories';
import IndividualGoals from './components/IndividualGoals';
import CompanyValues from './components/CompanyValues';

const ControlPanel = ({ t, companyId, locationId, userHaveFullAccess }) => {
  return (
    <Tabs>
    <TabList>
      {userHaveFullAccess && <Tab>{t('page_content.human_resources.control_panel.tab_category_scoring')}</Tab>}
      <Tab>{t('page_content.human_resources.control_panel.tab_individual_goals')}</Tab>
      <Tab>{t('page_content.human_resources.control_panel.tab_kfk_values')}</Tab>
    </TabList>

    {userHaveFullAccess && <TabPanel>
        <ScoringCategories
          companyId={companyId}
          locationId={locationId}
        />
    </TabPanel>}

    <TabPanel>
        <IndividualGoals
          companyId={companyId}
          locationId={locationId}
          userHaveFullAccess={userHaveFullAccess}
        />
    </TabPanel>

    <TabPanel>
        <CompanyValues
          companyId={companyId}
          userHaveFullAccess={userHaveFullAccess}
        />
    </TabPanel>
  </Tabs>
  );
};

ControlPanel.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default (withTranslation()(ControlPanel));
