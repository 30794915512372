import React, { useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader, Button } from 'shared';
import { IconRemove, IconPlus } from 'shared/Icons';
import { modalSizes } from 'shared/constants';
import '../styles.scss';

import { addDataDefinition, editDataDefinition } from '../../../actions';

const ScoringCategoriesModal = ({
  t,
  isOpen,
  companyId,
  currentUser,
  initialValues = {},
  handleCloseModal,
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const scoringOptions = [
    { id: 'range', name: t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.select_option_range') },
    { id: 'map', name: t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.select_option_map') },
  ];
  const initialScoringTypeValues = Object?.entries(initialValues?.scoring?.values || {})?.map(([key, value]) => ({ value: key, score: value }));
  const [scoringType, setScoringType] = useState({
    type: { id: get(initialValues?.scoring, 'type', null) },
    values: initialScoringTypeValues || [],
    default: 0,
  });

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const onChangeScoringType = (key, value) => {
    if (value?.id === 'map' || value?.id === 'range') {
      setScoringType((prevValues) => ({
        ...prevValues,
        [key]: value,
        values: [...prevValues.values] || [{ value: '', score: '' }],
      }));
    } else {
      setScoringType((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    }
  };

  const onChangeScoringValues = (index, key, value) => {
    const newValues = [...scoringType?.values];
    newValues[index][key] = value;
    setScoringType((prevValues) => ({
      ...prevValues,
      values: newValues,
    }));
  };

  const handleAddNewMapScoring = () => {
    setScoringType((PrevState) => ({ ...PrevState, values: [...PrevState.values, { value: '', score: '' }] }));
  };

  const handleRemoveMapScoring = (index) => {
    setScoringType((PrevState) => ({ ...PrevState, values: PrevState.values.filter((_, i) => i !== index) }));
  };

  const handleSave = async () => {
    const payload = {
      ...formValues,
      name: formValues?.name || null,
      user: currentUser?.id,
      order: formValues?.order || null,
      is_active: true,
      company: companyId,
      scoring: {
        type: scoringType?.type?.id,
        values: scoringType?.values?.reduce((acc, item) => {
          if (item?.value && item?.score && !Number.isNaN(Number(item?.score))) acc[item.value] = Number(item.score);
          return acc;
        }, {}),
        default: scoringType?.default || 0,
      },
    };

    setIsLoading(true);
    if (formValues?.id) {
      await editDataDefinition(companyId, formValues?.id, payload)
        .then(() => {
          closeModalAndRefetch();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      await addDataDefinition(companyId, payload)
        .then(() => {
          closeModalAndRefetch();
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Modal
      title={formValues?.id ? t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.title_placeholder_edit') : t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.title_placeholder_add')}
      disableSave={!formValues?.order || !formValues?.name}
      handleClose={handleCloseModal}
      size={modalSizes.large}
      handleSave={handleSave}
      isOpen={isOpen}
    >
      {isLoading ? (
        <ContentLoader />
      ) : (
        <div className="data_definitions_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.field_placeholder_order')}: *</div>
            <div className="right_input">
              <input
                type="text"
                value={get(formValues, 'order', '')}
                onChange={(e) => onChange(e.target.value, 'order')}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.field_placeholder_definition')}: *</div>
            <div className="right_input">
              <input
                type="text"
                value={get(formValues, 'name', '')}
                onChange={(e) => onChange(e.target.value, 'name')}
              />
            </div>
          </div>
          <div className="scoring_type">
            <div style={{ width: '100%' }}>
                <Select
                  options={scoringOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.select_placeholder_type')}
                  onChange={(value) => onChangeScoringType('type', value)}
                  value={scoringOptions?.find((a) => a.id === scoringType?.type?.id) || null}
                  styles={selectModalStyles}
                />
            </div>
            {scoringType?.type?.id && <div className="right_add_button">
                <Button type="delete" onClick={handleAddNewMapScoring}>
                    <IconPlus width="12px" height="12px" />
                </Button>
            </div>}
          </div>
          {
            (scoringType?.type?.id === 'map' || scoringType?.type?.id === 'range') &&
            <div className="map_scoring_layout">
                {scoringType?.values?.map((score, index) => {
                  return (
                    <div key={index} className="modal_row_scoring">
                        <div className="right_input">
                        <input
                          type="text"
                          placeholder={t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.input_placeholder_value')}
                          value={score?.value || ''}
                          onChange={(e) => onChangeScoringValues(index, 'value', e.target.value)}
                        />
                        </div>
                        <div className="right_input">
                        <input
                          type="text"
                          placeholder={t('page_content.human_resources.control_panel.category_scoring.category_scoring_modal.input_placeholder_score')}
                          value={score?.score || ''}
                          onChange={(e) => onChangeScoringValues(index, 'score', e.target.value?.replace(/[^0-9]/g, ''))}
                        />
                        </div>
                        <div>
                        <Button type="delete" onClick={() => handleRemoveMapScoring(index)}>
                            <IconRemove width="12px" height="12px" />
                        </Button>
                        </div>
                    </div>
                  );
                })}
            </div>
          }
        </div>
      )}
    </Modal>
  );
};

ScoringCategoriesModal.propTypes = {
  t: PropTypes.func,
  currentUser: PropTypes.object,
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  closeModalAndRefetch: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default (withTranslation()(ScoringCategoriesModal));
