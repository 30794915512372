import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import LimitedTextarea from 'shared/LimitedTextarea/index';
import { getLocale } from 'shared/DatePicker/constants';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { addAssignedEquipment, editAssignedEquipment } from '../../actions';

const AssignEquipmentModal = ({
  t,
  isOpen,
  workers,
  companyId,
  currentUser,
  fetchWorkers,
  takenByWorkers,
  equipmentStatus,
  isLoadingWorkers,
  handleCloseModal,
  acceptedByWorkers,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const onWorkerChange = (value, key) => {
    if (value.length > 3) fetchWorkers(key, value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
      user: currentUser?.id,
      status: formValues?.status,
      worker: formValues?.worker?.id,
      take_clerk: formValues?.take_clerk?.id,
      return_clerk: formValues?.return_clerk?.id,
    };

    if (formValues?.id) {
      await editAssignedEquipment(companyId, formValues?.id, payload);
    } else {
      await addAssignedEquipment(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.large}
      handleClose={handleCloseModal}
      title={formValues?.id ? t('page_content.human_resources.assigned_equipment.modal_title_placeholder_edit') : t('page_content.human_resources.assigned_equipment.modal_title_placeholder_add')}
      disableSave={!formValues?.name || !formValues?.quantity || !formValues?.date_of_take || !formValues?.status || !formValues?.worker || !formValues?.take_clerk}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="assigned_equipment_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_name')}:*</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'name', '')} onChange={(e) => onChange(e.target.value, 'name')} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_quantity')}:*</div>
              <div className="right_input">
                <input
                  type="text"
                  value={get(formValues, 'quantity', '')}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                    if (!Number.isNaN(sanitizedValue)) {
                      onChange(sanitizedValue, 'quantity');
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_taken')}:*</div>
              <div className="right_input">
                <DatePicker
                  className="date_picker"
                  placeholderText={t('page_content.human_resources.assigned_equipment.modal_datepicker_placeholder_date_of_take')}
                  dateFormat="dd.MM.yyyy"
                  selected={formValues?.date_of_take ? moment(formValues.date_of_take, 'YYYY-MM-DD').toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date_of_take')}
                  locale={getLocale(t)}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_returned')}:</div>
              <div className="right_input">
                <DatePicker
                  className="date_picker"
                  placeholderText={t('page_content.human_resources.assigned_equipment.modal_datepicker_placeholder_date_of_return')}
                  dateFormat="dd.MM.yyyy"
                  selected={formValues?.date_of_return ? moment(formValues.date_of_return, 'YYYY-MM-DD').toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date_of_return')}
                  locale={getLocale(t)}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_note')}:</div>
              <div className="right_limited_text_area">
                <LimitedTextarea autoResize value={formValues?.note || ''} onChange={(value) => onChange(value, 'note')} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_status')}:*</div>
              <div className="right_select">
                <Select
                  options={equipmentStatus}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_status')}
                  onChange={(value) => onChange(value.value, 'status')}
                  value={(equipmentStatus?.find((a) => a.value === formValues?.status)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_worker')}:*</div>
              <div className="right_select">
                <Select
                  options={formValues?.worker?.id ? [...workers, formValues?.worker] : workers}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isLoading={isLoadingWorkers}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_type_3_char')}
                  onChange={(value) => onChange(value, 'worker')}
                  onInputChange={(value) => onWorkerChange(value, 'worker')}
                  value={formValues?.worker?.id ? [...workers, formValues?.worker]?.find((a) => a.id === formValues?.worker?.id) : workers?.find((a) => a.id === formValues?.worker?.id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_take_clerk')}:*</div>
              <div className="right_select">
                <Select
                  options={formValues?.take_clerk?.id ? [...takenByWorkers, formValues?.take_clerk] : takenByWorkers}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isLoading={isLoadingWorkers}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_type_3_char')}
                  onChange={(value) => onChange(value, 'take_clerk')}
                  onInputChange={(value) => onWorkerChange(value, 'take_clerk')}
                  value={formValues?.take_clerk?.id ? [...takenByWorkers, formValues?.take_clerk]?.find((a) => a.id === formValues?.take_clerk?.id) : takenByWorkers?.find((a) => a.id === formValues?.take_clerk?.id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_return_clerk')}:</div>
              <div className="right_select">
                <Select
                  options={formValues?.return_clerk?.id ? [...acceptedByWorkers, formValues?.return_clerk] : acceptedByWorkers}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isLoading={isLoadingWorkers}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_type_3_char')}
                  onChange={(value) => onChange(value, 'return_clerk')}
                  onInputChange={(value) => onWorkerChange(value, 'return_clerk')}
                  value={formValues?.return_clerk?.id ? [...acceptedByWorkers, formValues?.return_clerk]?.find((a) => a.id === formValues?.return_clerk?.id) : acceptedByWorkers?.find((a) => a.id === formValues?.return_clerk?.id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

AssignEquipmentModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  isLoadingWorkers: PropTypes.bool,
  workers: PropTypes.object.isRequired,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  takenByWorkers: PropTypes.object.isRequired,
  fetchWorkers: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  equipmentStatus: PropTypes.array.isRequired,
  acceptedByWorkers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(AssignEquipmentModal));
