import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import posed from 'react-pose';
import moment from 'moment';
import * as appActions from 'app/actions';
import * as userActions from 'user/actions';
import { saveSortingAndFiltering } from 'industry/actions';
import * as industryDashboardActions from 'industry/containers/IndustryLocationDashboard/actions';
import {
  DatePicker,
  IntervalPicker,
  LocationSelector,
  RefreshButton,
  SubHeader,
  ZoomOutButton,
} from 'shared';
import {
  IconHamburgerOpen,
} from 'shared/Icons';
import { isFilterContainerVisible } from './helpers';
import './styles.scss';

const FilterDiv = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
});

class IndustrySubHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: null,
      start: null,
      end: null,
      label: null,
      sidebarOnMobile: false,
    };
  }

  componentDidMount() {
    const {
      dateRange,
      refreshInterval,
      currentUser,
    } = this.props;

    const dashboards = currentUser && currentUser.data && currentUser.data.dashboards ? currentUser.data.dashboards : {};

    // eslint-disable-next-line
    this.setState({
      interval: (typeof dashboards === 'object' && Object.prototype.hasOwnProperty.call(dashboards, 'interval')) ? dashboards.interval : refreshInterval,
      start: (typeof dashboards === 'object' && Object.prototype.hasOwnProperty.call(dashboards, 'start')) ? moment(dashboards.start) : dateRange.start,
      end: (typeof dashboards === 'object' && Object.prototype.hasOwnProperty.call(dashboards, 'end')) ? moment(dashboards.end) : dateRange.end,
      label: (typeof dashboards === 'object' && Object.prototype.hasOwnProperty.call(dashboards, 'label')) ? dashboards.label : dateRange.label,
    }, () => {
      this.saveSortingAndFiltering();
      this.props.setInterval({ interval: this.state.interval });
      this.props.setDateRange({
        start: this.state.start,
        end: this.state.end,
        label: this.state.label,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.dateRange !== prevProps.dateRange) {
      // eslint-disable-next-line
      this.setState({
        label: this.props.dateRange.label,
        start: moment(this.props.dateRange.start),
        end: moment(this.props.dateRange.end),
      });
    }
  }

  saveSortingAndFiltering = () => {
    const {
      interval,
      start,
      end,
      label,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.dashboards = {
        interval,
        start: start.toISOString(),
        end: end.toISOString(),
        label,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
    }
  }

  saveInterval = (interval) => {
    this.setState(
      { interval: interval.interval },
      () => {
        this.props.setInterval(interval);
        this.saveSortingAndFiltering();
      },
    );
  }

  saveDateRange = (label, start, end) => {
    this.setState({
      label,
      start: moment(start),
      end: moment(end),
    }, () => {
      this.props.setDateRange({
        start: moment(start),
        end: moment(end),
        label,
      });
      this.saveSortingAndFiltering();
    });
  }

  handleToggleSidebar = () => {
    this.setState(
      (prevState) => ({ sidebarOnMobile: !prevState.sidebarOnMobile }),
      () => {
        this.props.toggleSidebar(this.state.sidebarOnMobile);
      },
    );
  }

  render() {
    const {
      companyId,
      currentLocation,
      // dateRange,
      history,
      // refreshInterval,
      updateManualRefresh,
      zoomOut,
      isSettings,
    } = this.props;

    const {
      interval,
      start,
      end,
      label,
    } = this.state;

    return (
      <SubHeader>
        {
          !isSettings &&
          <div
            className="sidebar_mobile_toggle"
            onClick={() => {
              this.handleToggleSidebar();
            }}
          ><IconHamburgerOpen height="20px" width="20px" />
          </div>
        }

        <LocationSelector
          companyId={companyId}
          currentLocation={currentLocation}
          handleLocationChange={(location) => history.push(`/${companyId}/industry/location/${location}`)}
        />
        <FilterDiv
          className="IndustrySubHeader-filters"
          pose={isFilterContainerVisible(get(history, 'location.pathname')) ? 'visible' : 'hidden'}
        >
          <RefreshButton
            handleRefresh={() => updateManualRefresh(true)}
          />
          <IntervalPicker
            interval={interval}
            onSetInterval={(newInterval) => {
              this.saveInterval(newInterval);
            }}
          />
          {
            label && start && end &&
            <DatePicker
              currentLocation={currentLocation}
              startDate={start}
              endDate={end}
              dateRange={label}
              changeDate={(newLabel, newStart, newEnd) => {
                this.saveDateRange(newLabel, newStart, newEnd);
              }}
              forceRefresh={() => updateManualRefresh(true)}
              companyId={companyId}
              locationId={currentLocation}
            />
          }
          <ZoomOutButton
            handleZoomOut={zoomOut}
          />
        </FilterDiv>
      </SubHeader>
    );
  }
}

IndustrySubHeader.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentLocation: PropTypes.any,
  dateRange: PropTypes.object.isRequired,
  setDateRange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  refreshInterval: PropTypes.number.isRequired,
  setInterval: PropTypes.func.isRequired,
  updateManualRefresh: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  isSettings: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  dateRange: get(state, 'app.daterange'),
  companyId: get(state, 'app.company.id'),
  currentLocation: get(state, 'app.location'),
  refreshInterval: get(state, 'app.interval'),
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  setInterval: appActions.setInterval,
  setDateRange: appActions.setDateRange,
  fetchPageMeta: appActions.fetchPageMeta,
  logout: userActions.logout,
  updateManualRefresh: industryDashboardActions.updateManualRefresh,
  zoomOut: appActions.zoomOut,
  setCurrentUser: userActions.getCurrentUserSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndustrySubHeader));
