import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { numberSeparatorFormat } from 'industry/helpers';

class OrderBookingsEditModal extends Component {
  constructor() {
    super();
    this.state = {
      calculated_remaining: null,
      quantity_booked: null,
    };
  }

  onChange = (value) => {
    const { selectedBookingItem } = this.props;

    const quantity_booked = selectedBookingItem && selectedBookingItem.quantity_booked ? selectedBookingItem.quantity_booked : null;
    const new_quantity_booked = value ? parseFloat(value.replace(',', '.')) : 0;
    const quantity_remaining = selectedBookingItem && selectedBookingItem.quantity_remaining ? selectedBookingItem.quantity_remaining : '-';

    const delta = parseFloat(quantity_booked) - new_quantity_booked;
    const new_remaining = parseFloat(quantity_remaining) + delta;

    this.setState({
      calculated_remaining: new_remaining,
      quantity_booked: value,
    });
  }

  onSaveBookingsModal = (quantity_booked) => {
    const { onSaveBookingsModal } = this.props;

    if (quantity_booked !== null) {
      // Convert the comma to a dot before saving
      const parsedQuantity = parseFloat(quantity_booked.replace(',', '.'));
      onSaveBookingsModal(parsedQuantity);
    }

    this.setState({
      calculated_remaining: null,
      quantity_booked: null,
    });
  }

  truncateDecimals = (num, digits) => {
    const numS = num.toString().replace('.', ',');
    const decPos = numS.indexOf(',');
    const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits;
    const trimmedResult = numS.substr(0, substrLength);
    const finalResult = Number.isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult.replace(',', '.'));
  }

  closeModal = () => {
    const { closeEditModal } = this.props;

    this.setState({
      calculated_remaining: null,
      quantity_booked: null,
    }, closeEditModal());
  }

  render() {
    const { calculated_remaining, quantity_booked } = this.state;

    const {
      i18n,
      t,
      isEditModalOpen,
      selectedBookingItem,
    } = this.props;

    return (
      <Modal
        isOpen={isEditModalOpen}
        handleSave={() => this.onSaveBookingsModal(quantity_booked)}
        handleClose={this.closeModal}
        disableSave={!quantity_booked}
        title={selectedBookingItem && selectedBookingItem.order_item && selectedBookingItem.order_item.name ? selectedBookingItem.order_item.name : ''}
      >
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('page_content.orders.order_details.bookings_tab.table_column_quantity_total')}
                </td>
                <td className="value">
                  {selectedBookingItem && selectedBookingItem.order_item.quantity ?
                    numberSeparatorFormat(i18n.language, selectedBookingItem.order_item.quantity, 2, 2, true) : '-'}
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('page_content.orders.order_details.bookings_tab.table_column_quantity_booked')}
                </td>
                <td className="value">
                  <input
                    type="text"
                    value={quantity_booked !== null ? quantity_booked : (selectedBookingItem && selectedBookingItem.quantity_booked ?
                      this.truncateDecimals(selectedBookingItem.quantity_booked, 2).toString().replace('.', ',') : '')}
                    onChange={(e) => {
                      // Allow only numbers and a single comma
                      const value = e.target.value;
                      if (/^\d*[,]?\d*$/.test(value)) {
                        const numberOfDecimals = value.split(',')[1]?.length || 0;
                        if (numberOfDecimals <= 3) {
                          this.onChange(value);
                        }
                      }
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('page_content.orders.order_details.bookings_tab.table_column_quantity_remaining')}
                </td>
                <td className="value">
                  {calculated_remaining !== null ? numberSeparatorFormat(i18n.language, calculated_remaining, 2, 2, true) : (selectedBookingItem && selectedBookingItem.quantity_remaining ?
                    numberSeparatorFormat(i18n.language, selectedBookingItem.quantity_remaining, 2, 2, true) : '-')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

OrderBookingsEditModal.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  closeEditModal: PropTypes.func.isRequired,
  onSaveBookingsModal: PropTypes.func.isRequired,
  isEditModalOpen: PropTypes.bool.isRequired,
  selectedBookingItem: PropTypes.object,
};

export default withTranslation()(OrderBookingsEditModal);
