import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import Tooltip from 'rc-tooltip';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { get, sortBy } from 'lodash';
import { Table, Button, ContentLoader, Modal, Notification, TableButtons, DateFilters, ValuesMapper, ConfirmationModal } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import { IconSearch, IconPopup } from 'shared/Icons';
import sliderIcon from 'shared/Icons/slider_03.png';
import refreshIcon from 'shared/Icons/refresh.png';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { setDateRange } from 'app/actions';
import { ordersCreatedAndUpdated, modalSizes, defaultDateTimeFormat, defaultDateFormat } from 'shared/constants';
import { checkAccessOnPage, redirectToHomePage, checkModules, checkActiveFilters, numberSeparatorFormat, checkAccess, styledStatusOptions } from 'industry/helpers';
import 'react-table/react-table.css';
import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import '../style.scss';
import { getOrders, patchOrders, getLocations, getOrderTypes, getProductTypes, getLines, createOrder, getDepartments, getDepartmentTechnologies, getReferentMembers } from '../actions';
import CreateOrderModal from './CreateOrderModal';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();

    const searchParams = new URLSearchParams(window.location.search);
    const sortingAndFiltering = (props.currentUser) ? props.currentUser.data : {};

    let query = searchParams.get('query') || null;
    let search_value = searchParams.get('searchValue') || 'external_id';
    let not_booked = searchParams.get('notBooked') || false;
    let dateFilterPrefix = searchParams.get('dateFilterPrefix') || null;
    let startTime = searchParams.get('startTime') || null;
    let endTime = searchParams.get('endTime') || null;

    // removed 'all' status from the status array, so this is here for the users that have saved 'all' status
    // and are trying to send the link to someone (all status is now default)
    let selectedStatus = searchParams.get('status') !== 'all' ? searchParams.get('status') : '' || '';

    let selectedSort = (searchParams.get('order_by') || 'updated_at').replace(/^-/, '');
    let selectedAscDesc = searchParams.get('order_by') ? (!!searchParams.get('order_by')?.startsWith('-')) : true;
    let selectedLocationId = searchParams.get('locationId') || null;
    let selectedReferentId = searchParams.get('referentId') || null;
    selectedReferentId = selectedReferentId ? parseInt(selectedReferentId, 10) : null;
    let selectedOrderType = searchParams.get('orderType') || '';
    selectedOrderType = selectedOrderType ? parseInt(selectedOrderType, 10) : '';
    let selectedLine = searchParams.get('line') || '';
    selectedLine = selectedLine ? parseInt(selectedLine, 10) : '';
    let selectedDepartment = searchParams.get('selectedDepartment') || '';
    let selectedDepartmentTechnology = searchParams.get('selectedDepartmentTechnology') || '';

    this.defaultFilters = {
      query,
      search_value,
      not_booked,
      dateFilterPrefix,
      startTime,
      endTime,
      selectedStatus,
      selectedSort,
      selectedAscDesc,
      selectedLocationId,
      selectedReferentId,
      selectedOrderType,
      selectedLine,
      selectedDepartment,
      selectedDepartmentTechnology,
    };

    let filtersActive = false;
    if (sortingAndFiltering.orderList && searchParams?.size === 0) {
      const sAndF = sortingAndFiltering.orderList;

      query = sAndF.query || null;
      search_value = sAndF.search_value || 'external_id';
      not_booked = sAndF.not_booked || false;
      dateFilterPrefix = sAndF.dateFilterPrefix || null;
      startTime = sAndF.startTime || null;
      endTime = sAndF.endTime || null;

      // removed 'all' status from the status array, so this is here for the users that have saved 'all' status (all status is now default)
      selectedStatus = sAndF.selectedStatus !== 'all' ? sAndF.selectedStatus : '' || '';

      selectedSort = sAndF.selectedSort || 'updated_at';
      selectedAscDesc = sAndF.selectedAscDesc === 'desc';
      selectedLocationId = sAndF.selectedLocationId || null;
      selectedReferentId = sAndF.selectedReferentId || null;
      selectedOrderType = sAndF.selectedOrderType || '';
      selectedLine = sAndF.selectedLine || null;
      selectedDepartment = sAndF.selectedDepartment || '';
      selectedDepartmentTechnology = sAndF.selectedDepartmentTechnology || '';
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }

    this.state = {
      query,
      orders: [],
      isLoadingOrders: false,
      isLoadingExport: false,
      search_value,
      previous: null,
      next: null,
      count: null,
      selectedStatus,
      selectedSort,
      selectedAscDesc,
      showFilters: true,
      locationOptions: [],
      selectedLocationId,
      startTime,
      endTime,
      isStatusModalOpen: false,
      isLoadingStatusModal: true,
      statusModalData: null,
      filtersActive,
      statuses: [],
      orderTypes: [],
      selectedOrderType,
      lines: [],
      selectedLine,
      isCreateOrderModalOpen: false,
      check_bom: false,
      not_booked,
      departments: [],
      selectedDepartment,
      departmentTechnologies: [],
      selectedDepartmentTechnology,
      dateFilterPrefix,
      referentOptions: [],
      selectedReferentId,
      showBookedFilter: false,
      searchParams,
      date: {
        start: moment.utc(startTime, ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'DD.MM.YYYY.', 'DD.MM.YYYY.-HH.mm']).toISOString(),
        end: moment.utc(endTime, ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'DD.MM.YYYY.', 'DD.MM.YYYY.-HH.mm']).toISOString(),
        filters: dateFilterPrefix,
      },
      clearDateFilters: false,
      dateFilterOption: [],
      ordersArchivePermission: false,
      showConfirmationDialog: false,
      orderToArchive: null,
      isReadOnly: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId, showOrdersArchive, company_short_code } = this.props;

    this.setState({ isLoadingOrders: true });

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Orders');
        const bookingsModule = modules.find((m) => m.name === 'Order bookings');
        if (bookingsModule && bookingsModule.is_active) {
          this.setState({ showBookedFilter: true });
        }
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only --- check if we need it on order list
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }

        if (showOrdersArchive) {
          checkAccess('orders-archive', companyId)
            .then((re) => {
              const data = re.data;
              const orderArchiveAccess = data.access;
              if (orderArchiveAccess === 10) {
                this.setState({
                  ordersArchivePermission: true,
                });
              }
            });
        }
      });

    this.checkForBOMStatus();

    getOrderTypes(locationId)
      .then((re) => {
        this.setState({
          orderTypes: re && re.data && re.data.results ? re.data.results : [],
        });
      });

    getProductTypes(companyId)
      .then((res) => {
        this.setState({
          product_types: get(res, 'data.results') || [],
        });
      });

    getLocations(companyId)
      .then((res) => {
        this.setState({
          locationOptions: res.data.results || [],
        });
      });

    getLines(company_short_code, locationId)
      .then((res) => {
        this.setState({
          lines: get(res, 'data.results') || [],
        });
      });

    getDepartments(locationId)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
        });
      })
      .catch((error) => console.log(error));

    getDepartmentTechnologies(locationId, '')
      .then((resp) => {
        this.setState({
          departmentTechnologies: resp.data.results || [],
        });
      });

    // getCompanyMembers(companyId)
    //   .then((resp) => {
    //     const sorted = resp.data.results.filter(user => user.first_name !== '' || user.last_name !== '').sort((a, b) => {
    //       if (a.last_name < b.last_name) { return -1; }
    //       if (a.last_name > b.last_name) { return 1; }
    //       return 0;
    //     });
    //     sorted.unshift({ user_id: -1, first_name: 'Any', last_name: '' });
    //     this.setState({
    //       referentOptions: sorted || [],
    //     });
    //   });

    getReferentMembers(companyId)
      .then((resp) => {
        if (resp.data) {
          const sorted = sortBy(resp.data, ['last_name', 'first_name']);
          sorted.unshift({ id: -1, first_name: 'Any', last_name: '' });
          this.setState({
            referentOptions: sorted || [],
          });
        }
      });

    this.setState({ dateFilterOption: this.getDateFilterOptions() });
    this.getOrders();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.showBookedFilter !== this.state.showBookedFilter) {
      this.setState({ dateFilterOption: this.getDateFilterOptions() });
    }
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  onFilterChange = (key, value) => {
    const { orderByReferent, locationConfig, company_short_code, filter_orders_by_location } = this.props;
    const { searchParams, departments, departmentTechnologies, ordersArchivePermission } = this.state;
    this.setState({
      [key]: value,
    }, () => {
      // eslint-disable-next-line react/prop-types
      const { history, location } = this.props;

      switch (key) {
        case 'search_value':
          if (value === 'external_order_id') {
            if (company_short_code === 'iverpan') {
              searchParams.set('searchValue', value);
            } else {
              searchParams.delete('searchValue');
            }
          } else if (value) {
            searchParams.set('searchValue', value);
          } else {
            searchParams.delete('searchValue');
          }
          break;
        case 'selectedStatus':
          if (value === 'archived' && ordersArchivePermission) {
            searchParams.set('status', value);
          } else if (value && value !== 'archived') {
            searchParams.set('status', value);
          } else {
            searchParams.delete('status');
          }
          break;
        case 'selectedSort':
          const currentAscDesc = this.state.selectedAscDesc;
          const sortValue = value;
          const sortParam = currentAscDesc ? `-${sortValue}` : sortValue;
          if (value) {
            searchParams.set('order_by', sortParam);
          } else {
            searchParams.delete('order_by');
          }
          break;
        case 'selectedAscDesc':
          const sortValueToUpdate = this.state.selectedSort;
          const ascDescValue = value;
          const sortParamToUpdate = ascDescValue ? `-${sortValueToUpdate}` : sortValueToUpdate;
          if (value) {
            searchParams.set('order_by', sortParamToUpdate);
          } else {
            searchParams.delete('order_by');
          }
          break;
        case 'selectedOrderType':
          if (value) {
            searchParams.set('orderType', value);
          } else {
            searchParams.delete('orderType');
          }
          break;
        case 'selectedLine':
          if (value) {
            searchParams.set('line', value);
          } else {
            searchParams.delete('line');
          }
          break;
        case 'not_booked':
          if (locationConfig?.config?.orders_check_bookings) {
            if (value) {
              searchParams.set('notBooked', value);
            } else {
              searchParams.delete('notBooked');
            }
          } else {
            searchParams.delete('notBooked');
          }
          break;
        case 'date':
          const dateFilterOption = this.getDateFilterOptions();
          const dateFilter = dateFilterOption.find((option) => option.id === value.filters);
          if (dateFilter && value.start && value.end) {
            searchParams.set('startTime', moment.utc(value.start).format(dateFilter?.showTime ? 'DD.MM.YYYY.-HH.mm' : defaultDateFormat));
            searchParams.set('endTime', moment.utc(value.end).format(dateFilter?.showTime ? 'DD.MM.YYYY.-HH.mm' : defaultDateFormat));
            searchParams.set('dateFilterPrefix', dateFilter?.id);
          } else {
            searchParams.delete('startTime');
            searchParams.delete('endTime');
            searchParams.delete('dateFilterPrefix');
          }
          break;
        case 'selectedLocationId':
          if (!filter_orders_by_location) {
            if (value) {
              searchParams.set('locationId', value);
            } else {
              searchParams.delete('locationId');
            }
          } else {
            searchParams.delete('locationId');
          }
          break;
        case 'selectedReferentId':
          if (orderByReferent) {
            if (value) {
              searchParams.set('referentId', value);
            } else {
              searchParams.delete('referentId');
            }
          } else {
            searchParams.delete('referentId');
          }
          break;
        case 'selectedDepartment':
          if (departments && departments.length > 0) {
            if (value) {
              searchParams.set('department', value);
            } else {
              searchParams.delete('department');
            }
          } else {
            searchParams.delete('department');
          }
          break;
        case 'selectedDepartmentTechnology':
          if (departmentTechnologies && departmentTechnologies.length > 0) {
            if (value) {
              searchParams.set('departmentTechnology', value);
            } else {
              searchParams.delete('departmentTechnology');
            }
          } else {
            searchParams.delete('departmentTechnology');
          }
          break;
        default:
          if (value) {
            searchParams.set(key, value);
          } else {
            searchParams.delete(key);
          }
          break;
      }

      // Construct the new URL with updated search parameters
      const queryString = searchParams.toString();
      // eslint-disable-next-line react/prop-types
      const url = `${location.pathname}${queryString ? `?${queryString}` : ''}`;

      // Update the browser URL without refreshing
      history.push(url);
      this.getOrders();
      this.saveSortingAndFiltering();

      this.setState({ searchParams });
    });
  };

  onSaveOrderModal = (order) => {
    createOrder([order])
      .then((res) => {
        if (res && res.data && res.data.orders) {
          const currentURL = window.location.href;
          const updatedURL = `${currentURL}/${res.data.orders[0]}`;
          window.location.href = updatedURL;
        }
      });
  }

  onCloseOrderModal = () => {
    this.setState({
      isCreateOrderModalOpen: false,
    });
  }

  getOrders = () => {
    const { companyId, locationId, locationConfig, orderByReferent, company_short_code, filter_orders_by_location } = this.props;
    const {
      selectedSort,
      selectedAscDesc,
      selectedLocationId,
      selectedStatus,
      selectedOrderType,
      selectedLine,
      query,
      search_value,
      check_bom,
      not_booked,
      selectedDepartment,
      selectedDepartmentTechnology,
      selectedReferentId,
      departments,
      departmentTechnologies,
      date,
    } = this.state;

    let sTime = '';
    let eTime = '';
    if (date && date.start && date.end && date.filters) {
      const dateFilterOption = this.getDateFilterOptions();
      const dateFilter = dateFilterOption.find((option) => option.id === date.filters);
      if (dateFilter?.showTime) {
        sTime = date.start ? moment.utc(date.start).toISOString() : null;
        eTime = date.end ? moment.utc(date.end).toISOString() : null;
      } else {
        sTime = date.start ? moment.utc(date.start).format('YYYY-MM-DDT00:00:00') : null;
        eTime = date.end ? moment.utc(date.end).format('YYYY-MM-DDT23:59:59') : null;
      }
    }

    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }

    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      let url = `/api/v1/orders/orders/?company=${companyId}`;

      if (query && search_value === 'external_id') {
        url += `&external_id=${query}`;

        const asc = selectedAscDesc ? '-' : '';
        url += `&order_by=${asc}${selectedSort}`;

        if (selectedLocationId && selectedLocationId !== 'initial' && selectedLocationId !== 'all' && !filter_orders_by_location) {
          url += `&location=${selectedLocationId}`;
        }

        if (filter_orders_by_location) {
          url += `&location=${locationId}`;
        }

        if (selectedStatus && selectedStatus !== 'all' && selectedStatus !== 'archived') {
          url += `&status=${selectedStatus}`;
        }

        if (selectedStatus && selectedStatus === 'archived') {
          url += '&show_all=true';
        }

        if (selectedOrderType) {
          url += `&order_type=${selectedOrderType}`;
        }

        if (selectedLine) {
          url += `&line=${selectedLine}`;
        }

        if (selectedDepartment && (departments && departments.length > 0)) {
          url += `&department=${selectedDepartment}`;
        }

        if (date && date.start && date.end && date.filters) {
          if (sTime && eTime) {
            url += `&${date.filters}_after=${sTime}`;
          }
          if (eTime) {
            url += `&${date.filters}_before=${eTime}`;
          }
        }

        if (not_booked && (locationConfig?.config?.orders_check_bookings)) {
          url += '&not_booked=true';
        }

        this.fetchData(url);
      } else {
        const asc = selectedAscDesc ? '-' : '';
        url += `&order_by=${asc}${selectedSort}`;

        if (query && search_value === 'external_order_id' && company_short_code === 'iverpan') {
          url += `&order_id=${query}`;
        }

        if (query && search_value === 'customer_name') {
          url += `&customer=${query}`;
        }

        if (query && search_value === 'product_code') {
          url += `&product_code_contains=${query}`;
        }

        if (selectedStatus && selectedStatus !== 'all' && selectedStatus !== 'archived') {
          url += `&status=${selectedStatus}`;
        }

        if (selectedStatus && selectedStatus === 'archived') {
          url += '&show_all=true';
        }

        if (selectedLocationId && selectedLocationId !== 'initial' && selectedLocationId !== 'all' && !filter_orders_by_location) {
          url += `&location=${selectedLocationId}`;
        }

        if (selectedReferentId && selectedReferentId !== -1 && orderByReferent) {
          url += `&order_user=${selectedReferentId}`;
        }

        if (date && date.start && date.end && date.filters) {
          if (sTime) {
            url += `&${date.filters}_after=${sTime}`;
          }
          if (eTime) {
            url += `&${date.filters}_before=${eTime}`;
          }
        }

        if (filter_orders_by_location) {
          url += `&location=${locationId}`;
        }

        if (selectedOrderType) {
          url += `&order_type=${selectedOrderType}`;
        }

        if (selectedLine) {
          url += `&line=${selectedLine}`;
        }

        if (check_bom) {
          url += `&check_bom=${check_bom}`;
        }

        if (locationConfig && locationConfig.config && locationConfig.config.orders_check_bookings) {
          url += '&check_bookings=true';
        }

        if (not_booked && (locationConfig?.config?.orders_check_bookings)) {
          url += '&not_booked=true';
        }

        if (selectedDepartment && (departments && departments.length > 0)) {
          url += `&department=${selectedDepartment}`;
        }

        if (selectedDepartmentTechnology && (departmentTechnologies && departmentTechnologies.length > 0)) {
          url += `&technology=${selectedDepartmentTechnology}`;
        }

        this.fetchData(url);
        this.saveSortingAndFiltering();
      }
    }, 500);
  }

  getListOfBOMItems = (array) => {
    return array && array.length > 0 ? array.map((item) => (
      <p key={item.material_code}>{item.name}</p>
    )) : '';
  }

  saveSortingAndFiltering = () => {
    const {
      selectedAscDesc,
      selectedLocationId,
      selectedSort,
      selectedStatus,
      selectedOrderType,
      selectedLine,
      selectedReferentId,
      date,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.orderList = {
        selectedAscDesc: selectedAscDesc ? 'desc' : 'asc',
        selectedLocationId,
        selectedSort,
        selectedStatus,
        selectedOrderType,
        selectedLine,
        selectedReferentId,
      };

      if (date.start && date.end && date.filters) {
        const dateFilterOption = this.getDateFilterOptions();
        const dateFilter = dateFilterOption.find((option) => option.id === date.filters);
        currentUser.data.orderList = {
          ...currentUser.data.orderList,
          startTime: date?.start ? (dateFilter?.showTime ? moment.utc(date.start).toISOString() : moment.utc(date?.start).utcOffset(0, true).startOf('day').toISOString()) : '',
          endTime: date?.end ? (dateFilter?.showTime ? moment.utc(date.end).toISOString() : moment.utc(date?.end).utcOffset(0, true).endOf('day').toISOString()) : '',
          dateFilterPrefix: date?.filters,
        };
      }
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.orderList);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  fetchData = (url) => {
    const { isLoadingOrders } = this.state;

    if (!isLoadingOrders) {
      this.setState({
        isLoadingOrders: true,
      });
    }

    getOrders(url)
      .then((res) => {
        this.setState({
          orders: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          count: get(res, 'data.count'),
          isLoadingOrders: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  }

  handleClear = () => {
    const { searchParams } = this.state;
    // eslint-disable-next-line react/prop-types
    const { history, location } = this.props;

    this.setState({
      query: '',
    }, () => {
      searchParams.delete('query');

      const newSearch = searchParams.toString();
      // eslint-disable-next-line react/prop-types
      const updatedUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;

      history.replace(updatedUrl);
      this.getOrders();
    });
  }

  clearAllFilters = () => {
    this.setState((prevState) => ({
      startTime: null,
      endTime: null,
      selectedStatus: '',
      selectedLocationId: null,
      selectedOrderType: '',
      selectedLine: '',
      selectedReferentId: null,
      dateFilterPrefix: '',
      query: '',
      selectedDepartment: '',
      selectedDepartmentTechnology: '',
      not_booked: false,
      date: {},
      clearDateFilters: !prevState.clearDateFilters,
      searchParams: new URLSearchParams(),
    }), () => {
      this.removeAllSearchParams();
      this.saveSortingAndFiltering();
      this.getOrders();
    });
  }

  removeAllSearchParams = () => {
    // eslint-disable-next-line react/prop-types
    const { history, location } = this.props;

    // eslint-disable-next-line react/prop-types
    const urlWithoutSearchParams = location.pathname;

    history.replace(urlWithoutSearchParams);
  };

  redirectToDetails = (row, e) => {
    const selection = window.getSelection();
    if (selection.toString().length === 0) {
      const { companyId, locationId, history } = this.props;
      const orderId = row.original.id;
      if (e.metaKey || e.ctrlKey) {
        window.open(`/${companyId}/industry/location/${locationId}/orders/${orderId}`, '_blank');
      } else {
        history.push(`/${companyId}/industry/location/${locationId}/orders/${orderId}`);
      }
    }
  }

  handleSorting = (sortData) => {
    this.setState({ isLoadingOrders: true });
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: !!sortData.desc,
    }, () => {
      this.getOrders();
    });
  }

  handleStatusClick = (row, e) => {
    e.stopPropagation();
    const statusModalData = row.original;

    this.setState({
      isStatusModalOpen: true,
      statusModalData,
      isLoadingStatusModal: false,
    });

    // getOrderStatusHistory(orderId)
    //   .then((res) => {
    //     this.setState({
    //       selectedOrderHistory: get(res, 'data') || [],
    //       isLoadingStatusModal: false,
    //     });
    //   })
    //   .catch(err => console.log('Error while fetching order status history', err));
  }

  handleArchiveButton = async () => {
    const { orderToArchive } = this.state;
    const data = { is_deleted: true, status: 'archived' };
    await patchOrders(orderToArchive.id, data);
    this.setState({ showConfirmationDialog: false });
    this.getOrders();
  }

  handleExportOrderAnalysis = () => {
    const {
      selectedSort,
      selectedAscDesc,
      selectedLocationId,
      selectedStatus,
      selectedOrderType,
      selectedLine,
      query,
      search_value,
      locationOptions,
      not_booked,
      selectedDepartment,
      departments,
      selectedDepartmentTechnology,
      departmentTechnologies,
      selectedReferentId,
      date,
    } = this.state;
    const { i18n, t, locationId, orderByReferent, locationConfig, company_short_code, filter_orders_by_location } = this.props;

    let sTime = '';
    let eTime = '';
    if (date && date.start && date.end && date.filters) {
      const dateFilterOption = this.getDateFilterOptions();
      const dateFilter = dateFilterOption.find((option) => option.id === date.filters);
      if (dateFilter?.showTime) {
        sTime = date.start ? moment.utc(date.start).toISOString() : null;
        eTime = date.end ? moment.utc(date.end).toISOString() : null;
      } else {
        sTime = date.start ? moment.utc(date.start).startOf('day').toISOString() : null;
        eTime = date.end ? moment.utc(date.end).endOf('day').toISOString() : null;
      }
    }

    const language = i18n.language || 'en';
    let filters = '';

    const asc = selectedAscDesc ? '-' : '';

    filters += `&order_by=${asc}${selectedSort}`;

    if (query && search_value === 'external_id') {
      filters += `&external_id=${query}`;
    }

    if (query && search_value === 'external_order_id' && company_short_code === 'iverpan') {
      filters += `&order_id=${query}`;
    }

    if (query && search_value === 'customer_name') {
      filters += `&customer=${query}`;
    }

    if (query && search_value === 'product_code') {
      filters += `&product_code_contains=${query}`;
    }

    if (selectedStatus && selectedStatus !== 'all' && selectedStatus !== 'archived') {
      filters += `&status=${selectedStatus}`;
    }

    if (selectedStatus && selectedStatus === 'archived') {
      filters += '&show_all=true';
    }

    if (sTime) {
      filters += `&${date?.filters}_after=${sTime}`;
    }
    if (eTime) {
      filters += `&${date?.filters}_before=${eTime}`;
    }

    if (selectedLocationId && selectedLocationId !== 'initial' && selectedLocationId !== 'all' && !filter_orders_by_location) {
      filters += `&location=${selectedLocationId}`;
    }

    if (selectedReferentId && selectedReferentId !== -1 && orderByReferent) {
      filters += `&order_user=${selectedReferentId}`;
    }

    if (filter_orders_by_location) {
      filters += `&location=${locationId}`;
    }

    if (selectedOrderType) {
      filters += `&order_type=${selectedOrderType}`;
    }

    if (selectedLine) {
      filters += `&line=${selectedLine}`;
    }

    if (not_booked && (locationConfig?.config?.orders_check_bookings)) {
      filters += '&not_booked=true';
    }

    if (selectedDepartment && (departments && departments.length > 0)) {
      filters += `&department=${selectedDepartment}`;
    }

    if (selectedDepartmentTechnology && (departmentTechnologies && departmentTechnologies.length > 0)) {
      filters += `&technology=${selectedDepartmentTechnology}`;
    }

    if (language) {
      filters += `&lang=${language}`;
    }

    if (!date?.start || !date?.end) {
      return Notification('error', t('page_content.orders.export_analysis_msg'));
    }

    this.setState({
      isLoadingExport: true,
    });

    let exportUrlSegment = 'order_exports';
    const currentLocationOptions = locationOptions.find((x) => x.id === locationId);
    if (currentLocationOptions !== null
      && Object.prototype.hasOwnProperty.call(currentLocationOptions, 'config')
      && Object.prototype.hasOwnProperty.call(currentLocationOptions.config, 'export_order_endpoint')
    ) {
      exportUrlSegment = currentLocationOptions.config.export_order_endpoint;
    }

    api.get(`/api/v1/orders/${exportUrlSegment}/?company=${company_short_code}${filters}&format=xlsx&limit=99999`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'order_analysis.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.setState({
          isLoadingExport: false,
        });
      })
      .catch((e) => {
        console.error('Error while exporting orders', e);
        this.setState({
          isLoadingExport: false,
        });
        return Notification('error', 'An error occurred while exporting orders.', 'We could not perform your request, please try again.');
      });
  }

  closeStatusModal = () => {
    this.setState({
      isStatusModalOpen: false,
      isLoadingStatusModal: true,
      statusModalData: null,
    });
  }

  openCreateOrderModal = () => {
    this.setState({
      isCreateOrderModalOpen: true,
    });
  }

  checkForBOMStatus = () => {
    const { locationConfig } = this.props;
    if (locationConfig && locationConfig.config && locationConfig.config.orders_check_bom_availability) {
      this.setState({
        check_bom: true,
      });
    }
  }

  getDateFilterOptions = () => {
    const { showBookedFilter } = this.state;
    const { t, orderByDeadline, orderByPlannedStart } = this.props;

    const dateFilterOption = [
      { id: 'created_at', label: t('page_content.orders.sort_dropdown.created'), showTime: false },
      { id: 'updated_at', label: t('page_content.orders.sort_dropdown.updated'), showTime: false },
      { id: 'started_at', label: t('page_content.orders.sort_dropdown.started'), showTime: false },
      { id: 'finished_at', label: t('page_content.orders.sort_dropdown.finished'), showTime: false },
      showBookedFilter ? { id: 'booked_at', label: t('page_content.orders.sort_dropdown.booked'), showTime: false } : null,
      orderByDeadline ? { id: 'deadline', label: this.props.t('page_content.orders.sort_dropdown.deadline'), showTime: false } : null,
      orderByPlannedStart ? { id: 'planned_start', label: this.props.t('page_content.orders.sort_dropdown.planned_start'), showTime: false } : null,
    ].filter((option) => option !== null);

    return dateFilterOption;
  }

  handleArchiveConfirmation = (e, row) => {
    e.stopPropagation();
    this.setState({ orderToArchive: row.original, showConfirmationDialog: true });
  }

  render() {
    const { t,
      i18n,
      locationConfig,
      locationId,
      companyId,
      orderByReferent,
      orderByDeadline,
      orderByPlannedStart,
      company_short_code,
      statuses,
      filter_orders_by_location,
      archiveLabel,
    } = this.props;
    const {
      orders,
      isLoadingOrders,
      isLoadingExport,
      next,
      previous,
      query,
      search_value,
      selectedStatus,
      showFilters,
      locationOptions,
      selectedLocationId,
      isStatusModalOpen,
      isLoadingStatusModal,
      statusModalData,
      orderTypes,
      selectedOrderType,
      lines,
      selectedLine,
      isCreateOrderModalOpen,
      product_types,
      count,
      not_booked,
      departments,
      selectedDepartment,
      departmentTechnologies,
      selectedDepartmentTechnology,
      referentOptions,
      selectedReferentId,
      selectedAscDesc,
      selectedSort,
      clearDateFilters,
      dateFilterOption,
      ordersArchivePermission,
      isReadOnly,
    } = this.state;

    const create_order_button = locationConfig && locationConfig.config && locationConfig.config.create_order_button ? locationConfig.config.create_order_button : false;

    const searchFilterOptions = [
      company_short_code === 'iverpan' ? { value: 'external_order_id', name: t('page_content.orders.search_dropdown.order_number') } : null,
      { value: 'external_id', name: t('page_content.orders.search_dropdown.order_name') },
      { value: 'customer_name', name: t('page_content.orders.search_dropdown.customer_name') },
      { value: 'product_code', name: t('page_content.orders.search_dropdown.product_code') },
    ].filter((option) => option !== null);

    const statusOptions = [
      { value: 'created', name: t('page_content.orders.statuses.created') },
      { value: 'in_progress', name: t('page_content.orders.statuses.in_progress') },
      { value: 'in_warehouse', name: t('page_content.orders.statuses.in_warehouse') },
      { value: 'in_transit', name: t('page_content.orders.statuses.in_transit') },
      { value: 'finished', name: t('page_content.orders.statuses.finished') },
      { value: 'delivered', name: t('page_content.orders.statuses.delivered') },
      { value: 'scheduled', name: t('page_content.orders.statuses.scheduled') },
      { value: 'paused', name: t('page_content.orders.statuses.paused') },
      { value: 'partial_delivery', name: t('page_content.orders.statuses.partial_delivery') },
      { value: 'delivered_to_customer', name: t('page_content.orders.statuses.delivered_to_customer') },
      ordersArchivePermission ? { value: 'archived', name: archiveLabel || t('page_content.orders.statuses.archived') } : null,
    ].filter((option) => option !== null);

    const filteredStatuses = ordersArchivePermission ? statuses : statuses.filter((status) => status !== 'archived');

    const showColumnByLocationConfig = (translatedColumnName) => {
      if (get(locationConfig, 'config.orders_hidden_columns')) {
        if (get(locationConfig, 'config.orders_hidden_columns').length) {
          if (get(locationConfig, 'config.orders_hidden_columns').includes(translatedColumnName)) {
            return false;
          }
        }
      }
      return true;
    };

    return (
      <div className="orders_container order_list_container fullscreen">
        <div className="orders_toggle_search_row">
          <div className="input_container">
            <input name="search" onChange={(e) => this.onFilterChange('query', e.target.value)} placeholder={t('page_content.orders.search_for_placeholder')} value={query || ''} />
            {query && (
              <button
                onClick={this.handleClear}
              >
                &times;
              </button>
            )}
            <Select
              options={searchFilterOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isSearchable={false}
              onChange={(e) => this.onFilterChange('search_value', e.value)}
              value={(searchFilterOptions.find((sOption) => sOption.value === search_value)) || ''}
              styles={selectStyles}
            />
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>

          <div className="filter_toggle_controls">
            <div className={showFilters ? 'filter-active' : ''} />
            <img
              src={sliderIcon}
              alt="Filters"
              onClick={() => this.onFilterChange('showFilters', !showFilters)}
            />
            <img
              src={refreshIcon}
              alt="Refresh"
              onClick={this.getOrders}
              style={{ width: '18px', height: '18px' }}
            />
          </div>
        </div>
        {
          showFilters
            ? (
              <div className="filters_container">
                <div className="table_filters_row">
                  <div className="filter_block">
                    {
                      filteredStatuses && filteredStatuses.length
                        ? (
                          <Select
                            options={filteredStatuses.map((status) => ({ value: status, label: status === 'archived' && archiveLabel ? archiveLabel : status }))}
                            getOptionLabel={(option) => (option.label === archiveLabel ? archiveLabel : t([`page_content.orders.statuses.${option.label}`]))}
                            getOptionValue={(option) => option.value}
                            isSearchable
                            isClearable
                            placeholder={`${t('page_content.orders.status_placeholder')}`}
                            onChange={(e) => this.onFilterChange('selectedStatus', e?.value ?? null)}
                            value={(filteredStatuses.map((status) => ({ value: status, label: status })).find((status) => status.value === selectedStatus)) || ''}
                            styles={selectStyles}
                          />
                        )
                        : (
                          <Select
                            options={statusOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            isSearchable
                            isClearable
                            placeholder={`${t('page_content.orders.status_placeholder')}`}
                            onChange={(e) => this.onFilterChange('selectedStatus', e?.value ?? null)}
                            value={(statusOptions && statusOptions.find((status) => status.value === selectedStatus)) || ''}
                            styles={selectStyles}
                          />
                        )
                    }

                    <Select
                      options={orderTypes}
                      getOptionLabel={(orderType) => orderType.name}
                      getOptionValue={(orderType) => orderType.id}
                      placeholder={t('page_content.orders.all_order_types')}
                      isSearchable
                      isClearable
                      onChange={(e) => this.onFilterChange('selectedOrderType', e?.id ?? null)}
                      value={(orderTypes.find((orderType) => orderType.id === selectedOrderType)) || ''}
                      styles={selectStyles}
                    />

                    <Select
                      options={lines}
                      getOptionLabel={(line) => line.name}
                      getOptionValue={(line) => line.id}
                      placeholder={t('page_content.orders.all_lines')}
                      isSearchable
                      isClearable
                      onChange={(e) => this.onFilterChange('selectedLine', e?.id ?? null)}
                      value={(lines.find((line) => line.id === selectedLine)) || ''}
                      styles={selectStyles}
                    />

                    {
                      !filter_orders_by_location
                      && (
                        <Select
                          options={locationOptions}
                          getOptionLabel={(location) => location.name}
                          getOptionValue={(location) => location.id}
                          placeholder={t('page_content.orders.all_locations')}
                          isSearchable
                          isClearable
                          onChange={(e) => this.onFilterChange('selectedLocationId', e?.id ?? null)}
                          value={(locationOptions.find((option) => option.id === selectedLocationId)) || ''}
                          styles={selectStyles}
                        />
                      )
                    }
                    {
                      orderByReferent &&
                      <Select
                        options={referentOptions}
                        getOptionLabel={(referent) => `${referent.last_name} ${referent.first_name}`}
                        getOptionValue={(referent) => referent.id}
                        placeholder={t('page_content.orders.table_column_referent')}
                        isSearchable
                        isClearable
                        onChange={(e) => this.onFilterChange('selectedReferentId', e?.id ?? null)}
                        value={(referentOptions.find((option) => option.id === selectedReferentId)) || ''}
                        styles={selectStyles}
                      />
                    }
                    {
                      locationConfig?.config?.orders_check_bookings
                      && (
                        <div className="checkbox_wrapper">
                          <input
                            type="checkbox"
                            checked={not_booked}
                            onChange={() => this.onFilterChange('not_booked', !not_booked)}
                          />
                          {t('page_content.orders.not_booked_only')}
                        </div>
                      )
                    }
                    {departments && departments.length > 0
                      && (
                        <Select
                          options={departments}
                          getOptionLabel={(department) => department.name}
                          getOptionValue={(department) => department.id}
                          placeholder={t('page_content.orders.all_departments')}
                          isSearchable
                          isClearable
                          onChange={(e) => this.onFilterChange('selectedDepartment', e?.id ?? null)}
                          value={(departments.find((dep) => dep.id === selectedDepartment)) || ''}
                          styles={selectStyles}
                        />
                      )}
                    {
                      departmentTechnologies && departmentTechnologies.length > 0
                      && (
                        <Select
                          options={departmentTechnologies}
                          getOptionLabel={(technology) => technology.name}
                          getOptionValue={(technology) => technology.id}
                          placeholder={t('page_content.orders.all_technologies')}
                          isSearchable
                          isClearable
                          onChange={(e) => this.onFilterChange('selectedDepartmentTechnology', e?.id ?? null)}
                          value={(departmentTechnologies.find((tech) => tech.id === selectedDepartmentTechnology)) || ''}
                          styles={selectStyles}
                        />
                      )
                    }
                    <div className="clear_button">
                      <Button
                        type="plain"
                        onClick={this.clearAllFilters}
                      >
                        {t('page_content.orders.clear_all_button')}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="table_datepicker_row">
                  <div className="datepicker_box">
                    <DateFilters
                      selectedFilterProp={(filters, start, end) => this.onFilterChange(
                        'date',
                        { filters,
                          start: moment(start).utcOffset(0, true).toISOString(),
                          end: moment(end).utcOffset(0, true).toISOString(),
                        },
                      )}
                      filterOptions={dateFilterOption}
                      clearFilters={clearDateFilters}
                      defaultDate={this.state.date}
                    />
                  </div>
                  <div className="create_export_buttons">
                    {
                      create_order_button
                      && (
                        <div className="button_wrapper">
                          <Button
                            type="add"
                            disabled={isReadOnly}
                            onClick={this.openCreateOrderModal}
                          >
                            {t('page_content.orders.create_order')}
                          </Button>
                        </div>
                      )

                    }
                    <div className="button_wrapper">
                      <Button
                        type="export"
                        loading={isLoadingExport}
                        loadingTetx=""
                        onClick={this.handleExportOrderAnalysis}
                      >
                        {t('page_content.orders.export_order_analysis')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
        }
        <div>
          <Table
            style={{ userSelect: 'text' }}
            columns={[
              {
                Header: () => <span>{t('page_content.orders.table_column_name')}</span>,
                accessor: 'external_id',
                Cell: (row) => <span className="order-list__id">{row.value}</span>,
                show: showColumnByLocationConfig(t('page_content.orders.table_column_name', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_product_code')}</span>,
                accessor: 'product_code',
                Cell: (row) => (row.value ? row.value : '-'),
                show: showColumnByLocationConfig(t('page_content.orders.table_column_product_code', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_lines')}</span>,
                width: 120,
                accessor: 'lines',
                sortable: false,
                Cell: (row) => <ValuesMapper data={Object.values(row.value)} sliceNumber={1} />,
                show: showColumnByLocationConfig(t('page_content.orders.table_column_lines', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_order_number')}</span>,
                accessor: 'external_order_id',
                Cell: (row) => row.value || '-',
                show: company_short_code === 'iverpan',
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_referent')}</span>,
                accessor: 'users',
                Cell: (row) => {
                  const users = row.value.filter((u) => u.user_function === 'referent');
                  if (users.length) {
                    const values = users.map((u) => `${u.user.first_name} ${u.user.last_name}`);
                    return values.join(', ');
                  }
                  return '-';
                },
                show: orderByReferent ? showColumnByLocationConfig(t('page_content.orders.table_column_referent', { lng: 'en' })) : false,
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_customer_name')}</span>,
                width: 150,
                accessor: 'customer_name',
                Cell: (row) => row.value || '-',
                show: showColumnByLocationConfig(t('page_content.orders.table_column_customer_name', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_created')}</span>,
                width: 150,
                accessor: 'created_at',
                Cell: (row) => moment(row.value).format(ordersCreatedAndUpdated),
                show: showColumnByLocationConfig(t('page_content.orders.table_column_created', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_updated')}</span>,
                width: 150,
                accessor: 'updated_at',
                Cell: (row) => moment(row.value).format(ordersCreatedAndUpdated),
                show: showColumnByLocationConfig(t('page_content.orders.table_column_updated', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.sort_dropdown.deadline')}</span>,
                width: 150,
                accessor: 'deadline',
                Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
                show: orderByDeadline ? showColumnByLocationConfig(t('page_content.orders.sort_dropdown.deadline', { lng: 'en' })) : false,
              },
              {
                Header: () => <span>{t('page_content.orders.sort_dropdown.planned_start')}</span>,
                width: 150,
                accessor: 'planned_start',
                Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
                show: orderByPlannedStart ? showColumnByLocationConfig(t('page_content.orders.sort_dropdown.planned_start', { lng: 'en' })) : false,
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_started')}</span>,
                width: 150,
                accessor: 'started_at',
                Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
                show: showColumnByLocationConfig(t('page_content.orders.table_column_started', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_finished')}</span>,
                width: 150,
                accessor: 'finished_at',
                Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
                show: showColumnByLocationConfig(t('page_content.orders.table_column_finished', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_status')}</span>,
                width: 150,
                accessor: 'status',
                Cell: (row) => (
                  <div style={{ display: 'flex', justifyContent: 'center' }} onClick={(e) => this.handleStatusClick(row, e)}>
                    <span style={styledStatusOptions(row.value)}>{row.value ? row.value === 'archived' && archiveLabel ? archiveLabel :
                      t([`page_content.orders.statuses.${row.value}`]) : t('page_content.orders.statuses.no_status')}</span>
                    <span style={{ marginLeft: '10px' }}>
                      <IconPopup
                        height="28px"
                        width="28px"
                        fill="#4285F4"
                      />
                    </span>
                  </div>
                ),
                show: showColumnByLocationConfig(t('page_content.orders.table_column_status', { lng: 'en' })),
              },
              {
                Header: () => <span>BOM</span>,
                width: 60,
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                accessor: 'bom_missing',
                Cell: (row) => {
                  if (row.value && row.value === null) {
                    return <div>-</div>;
                  } if (row.value && row.value.length === 0) {
                    return <img src={checkMarkTrue} width="25px" height="20px" alt="" />;
                  }
                  return (
                    <Tooltip
                      id="tooltip-zoom"
                      trigger={['hover']}
                      placement="left"
                      overlay={this.getListOfBOMItems(row.original.bom_missing)}
                      overlayClassName="where-filter-tooltip"
                    >
                      <span aria-describedby="tooltip-zoom">
                        <img src={checkMarkFalse} width="25px" height="20px" alt="" />
                      </span>
                    </Tooltip>
                  );
                },
                show: showColumnByLocationConfig(t('BOM', { lng: 'en' })) && (get(locationConfig, 'config.orders_check_bom_availability') || false),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_bookings')}</span>,
                width: 90,
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                accessor: 'bookings',
                Cell: (row) => {
                  if (row.original && (!row.original.bookings || !row.original.bookings.items || !row.original.bookings.items_booked)) {
                    return <div>-</div>;
                  } if (row.original.bookings && row.original.bookings.items && row.original.bookings.items_booked) {
                    return (
                      <Tooltip
                        id="tooltip-zoom"
                        trigger={['hover']}
                        placement="left"
                        overlay={(
                          <p>
                            {row.original.bookings.items_booked}
                            {' '}
                            /
                            {' '}
                            {row.original.bookings.items}
                          </p>
                        )}
                        overlayClassName="where-filter-tooltip"
                      >
                        <span aria-describedby="tooltip-zoom">
                          <img src={row.original.bookings.items_booked === row.original.bookings.items ? checkMarkTrue : checkMarkFalse} width="25px" height="20px" alt="" />
                        </span>
                      </Tooltip>
                    );
                  }
                },
                show: showColumnByLocationConfig(t('page_content.orders.table_column_bookings', { lng: 'en' })) && (get(locationConfig, 'config.orders_check_bookings') || false),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_done')}</span>,
                width: 100,
                accessor: 'items_done',
                Cell: (row) => <div style={{ textAlign: 'right' }}>{`${numberSeparatorFormat(i18n.language, row.original.items_done, 0, 0, true)}`}</div>,
                show: showColumnByLocationConfig(t('page_content.orders.table_column_done', { lng: 'en' })),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_total')}</span>,
                width: 90,
                accessor: 'items_total',
                Cell: (row) => <div style={{ textAlign: 'right' }}>{`${numberSeparatorFormat(i18n.language, row.value, 0, 0, true)}`}</div>,
                show: showColumnByLocationConfig(t('page_content.orders.table_column_total', { lng: 'en' })),
              },
              {
                Header: () => <span>{archiveLabel || t('page_content.orders.table_column_archive')}</span>,
                width: 130,
                sortable: false,
                Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      disabled={get(row, 'original.is_deleted', false)}
                      style={{ width: 'fit-content' }}
                      onClick={(e) => this.handleArchiveConfirmation(e, row)}
                      type="danger"
                    >
                      {archiveLabel || (get(row, 'original.is_deleted', false) ?
                        t('page_content.orders.statuses.archived') :
                        t('page_content.orders.table_column_archive'))}
                      </Button>
                  </div>,
                show: ordersArchivePermission && showColumnByLocationConfig(archiveLabel || t('page_content.orders.table_column_archive', { lng: 'en' })),
                style: {
                  cursor: 'default',
                },
              },
            ]}
            data={orders}
            defaultPageSize={30}
            loading={isLoadingOrders}
            handleClick={(rowInfo, e) => this.redirectToDetails(rowInfo, e)}
            minRows={0}
            noDataText=""
            showPagination={false}
            sortable
            isCompact={!((companyId === 10 || companyId === 11))}
            defaultSorted={[{ id: selectedSort, desc: selectedAscDesc }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
            stickyHeader={!(orders.length <= 1)}
          />
          <TableButtons
            next={next}
            previous={previous}
            fetchFunction={this.fetchData}
            count={count}
            showCount
          />
        </div>

        <Modal
          isOpen={isStatusModalOpen}
          handleClose={this.closeStatusModal}
          size={modalSizes.large}
          title={t('page_content.orders.status_modal.status_title')}
        >
          {
            isLoadingStatusModal
            && <ContentLoader />
          }
          {
            (!isLoadingStatusModal && statusModalData)
            && (
              <div className="table_container">
                <div className="left_container">
                  <div className="left_container_first_column">
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.id')}</p>
                      <p className="data"><strong>{(statusModalData && statusModalData.id) ? statusModalData.id : '-'}</strong></p>
                    </div>
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.total_time')}</p>
                      <p className="data"><strong>{(statusModalData && statusModalData.timings && statusModalData.timings.total_time && (statusModalData.timings.total_time.hours || statusModalData.timings.total_time.minutes)) ? `${statusModalData.timings.total_time.hours}h : ${statusModalData.timings.total_time.minutes}m` : '-'}</strong></p>
                    </div>
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.total')}</p>
                      <p className="data"><strong>{`${numberSeparatorFormat(i18n.language, statusModalData.items_done, 0, 0)}/${numberSeparatorFormat(i18n.language, statusModalData.items_total, 0, 0)}`}</strong></p>
                    </div>
                  </div>
                  <div className="left_container_second_column">
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.waiting_time')}</p>
                      <p className="data"><strong>{(statusModalData && statusModalData.timings && statusModalData.timings.waiting_time && (statusModalData.timings.waiting_time.hours || statusModalData.timings.waiting_time.minutes)) ? `${statusModalData.timings.waiting_time.hours}h : ${statusModalData.timings.waiting_time.minutes}m` : '-'}</strong></p>
                    </div>
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.manufacturing_time')}</p>
                      <p className="data"><strong>{(statusModalData && statusModalData.timings && statusModalData.timings.manufacturing_time && (statusModalData.timings.manufacturing_time.hours || statusModalData.timings.manufacturing_time.minutes)) ? `${statusModalData.timings.manufacturing_time.hours}h : ${statusModalData.timings.manufacturing_time.minutes}m` : '-'}</strong></p>
                    </div>
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.waiting_for_delivery')}</p>
                      <p className="data"><strong>{(statusModalData && statusModalData.timings && statusModalData.timings.waiting_to_delivery && (statusModalData.timings.waiting_to_delivery.hours || statusModalData.timings.waiting_to_delivery.minutes)) ? `${statusModalData.timings.waiting_to_delivery.hours}h : ${statusModalData.timings.waiting_to_delivery.minutes}m` : '-'}</strong></p>
                    </div>
                    <div>
                      <p className="title">{t('page_content.orders.status_modal.warehouse_waiting')}</p>
                      <p className="data"><strong>{(statusModalData && statusModalData.timings && statusModalData.timings.warehouse_waiting && (statusModalData.timings.warehouse_waiting.hours || statusModalData.timings.warehouse_waiting.minutes)) ? `${statusModalData.timings.warehouse_waiting.hours}h : ${statusModalData.timings.warehouse_waiting.minutes}m` : ' -'}</strong></p>
                    </div>
                  </div>
                </div>

                <div className="right_container">
                  <table>
                    <thead>
                      <tr>
                        <td className="title">{t('page_content.orders.status_modal.status')}</td>
                        <td className="title">{t('page_content.orders.status_modal.history')}</td>
                      </tr>
                    </thead>
                    {
                      !isLoadingStatusModal
                      && (
                        <tbody>
                          <tr>
                            <td className="data"><strong>{t('page_content.orders.status_modal.created')}</strong></td>
                            <td className="data"><strong>{statusModalData && statusModalData.created_at ? moment(statusModalData.created_at).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                          <tr>
                            <td className="data"><strong>{t('page_content.orders.status_modal.started')}</strong></td>
                            <td className="data"><strong>{statusModalData && statusModalData.started_at ? moment(statusModalData.started_at).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                          <tr>
                            <td className="data"><strong>{t('page_content.orders.status_modal.finished')}</strong></td>
                            <td className="data"><strong>{statusModalData && statusModalData.finished_at ? moment(statusModalData.finished_at).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                          <tr>
                            <td className="data"><strong>{t('page_content.orders.status_modal.warehouse')}</strong></td>
                            <td className="data"><strong>{statusModalData && statusModalData.warehouse_at ? moment(statusModalData.warehouse_at).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                          <tr>
                            <td className="data"><strong>{t('page_content.orders.status_modal.ready')}</strong></td>
                            <td className="data"><strong>{statusModalData && statusModalData.ready_at ? moment(statusModalData.ready_at).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                          <tr>
                            <td className="data"><strong>{t('page_content.orders.status_modal.delivered')}</strong></td>
                            <td className="data"><strong>{statusModalData && statusModalData.delivered_at ? moment(statusModalData.delivered_at).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                        </tbody>
                      )

                    }
                  </table>
                </div>
              </div>
            )
          }
        </Modal>

        {
          isCreateOrderModalOpen
          && (
            <CreateOrderModal
              isCreateOrderModalOpen={isCreateOrderModalOpen}
              onSaveOrderModal={this.onSaveOrderModal}
              onCloseOrderModal={this.onCloseOrderModal}
              product_types={product_types}
              lines={lines}
              orderTypes={orderTypes}
              locationId={locationId}
              companyId={companyId}
              t={t}
            />
          )
        }
        <div />
        <ConfirmationModal
          customTitle={t('page_content.orders.table_column_archive_confirmation_title', { external_id: this.state.orderToArchive?.external_id })}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleArchiveButton}
          type="warning"
        />
      </div>
    );
  }
}

OrderList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  locationConfig: PropTypes.object.isRequired,
  orderByReferent: PropTypes.bool,
  orderByDeadline: PropTypes.bool,
  orderByPlannedStart: PropTypes.bool,
  showOrdersArchive: PropTypes.bool,
  company_short_code: PropTypes.string,
  statuses: PropTypes.array,
  filter_orders_by_location: PropTypes.bool,
  archiveLabel: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser'),
    locationConfig: get(state, 'app.locationConfig'),
    showOrdersArchive: get(state, 'app.location.config.orders_archive', false),
    orderByReferent: get(state, 'app.companyConfig.config.filter_orders_by_referent', false),
    orderByDeadline: get(state, 'app.companyConfig.config.filter_orders_by_deadline', false),
    orderByPlannedStart: get(state, 'app.companyConfig.config.filter_orders_by_planned_start', false),
    isProjectBased: get(state, 'app.companyConfig.config.project_based', false),
    company_short_code: get(state, 'app.companyConfig.short_code', null),
    statuses: get(state, 'app.companyConfig.config.statuses', []),
    filter_orders_by_location: get(state, 'app.companyConfig.config.filter_orders_by_location', false),
    archiveLabel: get(state, 'app.companyConfig.config.archive_label', ''),
  };
};

const mapDispatchToProps = {
  dispatchDateRange: setDateRange,
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(OrderList)));

// <select onChange={e => this.onFilterChange('selectedStatus', e.target.value)} value={selectedStatus}>
//   <option value="all">{t('page_content.orders.statuses.all')}</option>
//   {
//     statuses.map(s => (
//       <option key={s} value={s}>{t([`page_content.orders.statuses.${s}`])}</option>))
//   }
// </select>
