import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { defaultTimeFormat, ordersCreatedAndUpdated } from 'shared/constants';
import { selectStyles } from 'styles/modules/reactSelect';
import { numberSeparatorFormat } from 'industry/helpers';
import { Table, TableButtons, Button } from 'shared';
import { IconSearch } from 'shared/Icons';
import { getOEEBookings, getOrderTypes, getPaginatedOEEBookings } from '../actions';
import '../styles.scss';

const OEEBookings = ({ i18n, t, locationId }) => {
  const debounceTimeoutRef = useRef(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [selectedAscDesc, setSelectedAscDesc] = useState('desc');
  const [selectedSort, setSelectedSort] = useState('updated_at');

  const [tableData, setTableData] = useState({
    isLoading: true,
    data: [],
    count: 0,
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState({
    query: '',
    order_type: null,
    search_value: 'order_booking__order',
  });

  const fetchOrderTypes = () => {
    getOrderTypes(locationId)
      .then((re) => {
        setOrderTypes(get(re, 'data.results', []));
      })
      .catch(() => {
        setOrderTypes([]);
      });
  };

  const fetchOEEBookings = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let apiFilters = '';

    if (filters && filters.order_type) {
      apiFilters += `&order_type=${filters.order_type}`;
    }

    if (filters?.query && filters?.search_value === 'product_contains') {
      apiFilters += `&product_contains=${filters?.query}`;
    }

    if (filters?.query && filters?.search_value === 'order_booking__order') {
      apiFilters += `&order=${filters?.query}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;

    getOEEBookings(locationId, apiFilters)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch(() => {
        setTableData({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        });
      });
  };

  const fetchPaginatedOEEBookings = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedOEEBookings(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch(() => {
        setTableData(({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        }));
      });
  };

  useEffect(() => {
    fetchOrderTypes();
    fetchOEEBookings();
  }, []);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchOEEBookings();
    }, 200);
  }, [filters]);

  useEffect(() => {
    fetchOEEBookings();
  }, [selectedSort, selectedAscDesc]);

  const onFilterChange = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleClearFilters = () => {
    setFilters({
      query: '',
      order_type: null,
    });
  };

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    if (sortKey === 'order_booking.booked_at') {
      sortKey = 'order_booking__booked_at';
    }
    if (sortKey === 'order_booking.order.external_id') {
      sortKey = 'order_booking__order__external_id';
    }
    if (sortKey === 'product.code') {
      sortKey = 'product__code';
    }
    setSelectedSort(sortKey);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleClear = () => {
    setFilters((prevState) => ({
      ...prevState,
      query: '',
    }));
  };

  const searchFilterOptions = [
    { value: 'order_booking__order', name: t('page_content.oee.search_by_order_number') },
    { value: 'product_contains', name: t('page_content.oee.search_by_product_code') },
  ];

  return (
    <div className="oee__bookings">
      <div className="oee__bookings__filters">
        <div className="oee__bookings__filters_input">
          <input
            onChange={(e) => onFilterChange(e.target.value, 'query')}
            placeholder={t('page_content.oee.search')}
            value={filters.query || ''}
          />
          {filters?.query && (
            <button
              onClick={handleClear}
            >
              &times;
            </button>
          )}
          <Select
            options={searchFilterOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            isSearchable={false}
            placeholder={t('page_content.oee.select')}
            onChange={(e) => onFilterChange(e.value, 'search_value')}
            value={(searchFilterOptions.find((sOption) => sOption.value === filters.search_value)) || ''}
            styles={selectStyles}
          />
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
          </div>
        </div>

        <Select
          options={orderTypes}
          getOptionLabel={(orderType) => orderType.name}
          getOptionValue={(orderType) => orderType.id}
          placeholder={t('page_content.oee.filter_by_order_type')}
          isSearchable
          onChange={(e) => onFilterChange(e.id, 'order_type')}
          value={(orderTypes.find((orderType) => orderType.id === filters.order_type)) || ''}
          styles={selectStyles}
        />

        <Button onClick={handleClearFilters}>
          {t('shared.clear_button')}
        </Button>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.oee.order')}</span>,
            accessor: 'order.external_id',
            Cell: (row) => row.value || '-',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.oee.order_booking')}</span>,
            accessor: 'order_booking.booked_at',
            Cell: (row) => moment(row.value).format(ordersCreatedAndUpdated) || '-',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.oee.product_code')}</span>,
            accessor: 'product.code',
            Cell: (row) => (row?.value ?? '-'),
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.oee.quantity_produced')}</span>,
            accessor: 'quantity_produced',
            Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
            style: {
              cursor: 'default',
              alignItems: 'end',
            },
          },
          {
            Header: () => <span>{t('page_content.oee.quantity_max')}</span>,
            accessor: 'quantity_max',
            Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
            style: {
              cursor: 'default',
              alignItems: 'end',
            },
          },
          {
            Header: () => <span>{t('page_content.oee.stoppages_min')}</span>,
            accessor: 'stoppages_min',
            Cell: (row) => (row.value ? moment.utc(moment.duration(row.value, 'minutes').asMilliseconds()).format(defaultTimeFormat) : '-'),
            style: {
              cursor: 'default',
              alignItems: 'end',
            },
          },
          {
            Header: () => <span>OEE</span>,
            accessor: 'oee',
            Cell: (row) => (row && row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '0%'),
            style: {
              cursor: 'default',
              alignItems: 'end',
            },
          },
        ]}
        data={tableData.data || []}
        defaultPageSize={15}
        loading={tableData.isLoading}
        minRows={0}
        noDataText=""
        showPagination={false}
        selectedRow={null}
        defaultSorted={[{ id: 'updated_at', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <span style={{ float: 'right' }}>
        <TableButtons previous={tableData.previous} next={tableData.next} fetchFunction={fetchPaginatedOEEBookings} count={tableData.count} />
      </span>
    </div>
  );
};

OEEBookings.propTypes = {
  i18n: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(OEEBookings));
