import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from 'helpers/api';
import Select from 'react-select';
import { getCurrentUserSuccess } from 'user/actions';
import { saveSortingAndFiltering } from 'industry/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { checkActiveFilters, numberSeparatorFormat } from 'industry/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import { Table, Button, TableButtons } from 'shared';
import { IconWarning, IconSearch } from 'shared/Icons';
import { colors } from 'shared/colors';
import { getWarehouseData, getWarehouseDataURL, getListOfWarehouses } from '../actions';
import '../styles.scss';
import { Notification } from '../../../../shared/index';

class WarehouseStock extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    let selectedWarehouse = '';

    this.defaultFilters = {
      selectedWarehouse,
    };

    let filtersActive = false;
    if (sortingAndFiltering.warehouse) {
      const sAndF = sortingAndFiltering.warehouse;
      selectedWarehouse = sAndF.selectedWarehouse ? sAndF.selectedWarehouse : '';
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }

    this.state = {
      next: null,
      previous: null,
      warehouseData: [],
      isLoadingWarehouseData: true,
      listOfWarehouses: [],
      selectedWarehouse,
      filtersActive,
      count: null,
      query: '',
      loadingExport: false,
      selectedAscDesc: 'desc',
      selectedSort: 'created_at',
    };
  }

  componentDidMount() {
    const { selectedWarehouse } = this.state;
    const { locationId } = this.props;
    getListOfWarehouses(locationId)
      .then((res) => {
        if (selectedWarehouse) {
          this.fetchWarehouseData();
        }
        this.setState({
          listOfWarehouses: res.data.results || [],
          isLoadingWarehouseData: false,
        });
      });
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  fetchWarehouseData = () => {
    this.setState({
      isLoadingWarehouseData: true,
    });
    const { companyId, locationId } = this.props;
    const { selectedWarehouse, query, selectedAscDesc, selectedSort } = this.state;
    let filters = '';

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    if (selectedWarehouse && selectedWarehouse.id) {
      filters += `&warehouse=${selectedWarehouse.id}`;
    }
    if (query) {
      filters += `&material_code=${query}`;
    }

    getWarehouseData(companyId, locationId, filters)
      .then((res) => {
        this.setState({
          count: res && res.data ? res.data.count : null,
          warehouseData: res.data.results || [],
          next: res.data.next || null,
          previous: res.data.previous || null,
          isLoadingWarehouseData: false,
        });
      });
  }

  fetchWarehouseDataByUrl = (url) => {
    this.setState({
      isLoadingWarehouseData: true,
    });

    getWarehouseDataURL(url)
      .then((res) => {
        this.setState({
          warehouseData: res.data.results || [],
          next: res.data.next,
          previous: res.data.previous,
          isLoadingWarehouseData: false,
        });
      });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchWarehouseData();
    }, 600);
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { count, selectedWarehouse } = this.state;
    this.setState({
      loadingExport: true,
    });

    if (Object.keys(selectedWarehouse).length === 0) {
      return Notification('error', 'No selected warehouse');
    }

    let filters = '';

    if (selectedWarehouse && selectedWarehouse.id) {
      filters += `&warehouse=${selectedWarehouse.id}`;
    }

    api.get(`/api/v1/orders/stocks/?company=${companyId}&location=${locationId}${filters}&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'warehouse_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          loadingExport: false,
        });
      });
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
      this.saveSortingAndFiltering();
    });
  }

  handleSorting = (sortData) => {
    this.setState({ isLoadingOrders: true });
    let column = sortData.id;

    if (column === 'product_type.code' ||
    column === 'product_type.name' ||
    column === 'warehouse.name') {
      column = column.replace('.', '__');
    }

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchWarehouseData();
    });
  }

  clearFilters = () => {
    this.setState({
      selectedWarehouse: {},
      warehouseData: [],
      query: '',
      isLoadingWarehouseData: false,
      selectedSort: 'updated_at',
      selectedAscDesc: 'desc',
    }, () => {
      this.fetchWarehouseData();
      this.saveSortingAndFiltering();
    });
  }

  saveSortingAndFiltering = () => {
    const {
      selectedWarehouse,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.warehouse = {
        selectedWarehouse,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.warehouse);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  render() {
    const { t, i18n } = this.props;
    const {
      warehouseData,
      isLoadingWarehouseData,
      listOfWarehouses,
      selectedWarehouse,
      next,
      previous,
      query,
      count,
      loadingExport,
    } = this.state;

    const warehouseOptions = [
      ...listOfWarehouses.sort((a, b) => a.name.localeCompare(b.name)).map((warehouse) => ({
        name: warehouse.name,
        id: warehouse.id,
      })),
    ];

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '200px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0px 0px 0px 5px',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 3px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 200,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <div className="warehouse_container">
        <div className="warehouse_toolbar_area">
          <div className="warehouse_toolbar_input_container">
            <input
              onChange={(e) => this.handleQueryOrFilterChange('query', e.target.value)}
              placeholder={t('page_content.warehouse.input_placeholder')}
              value={query || ''}
              type="text"
            />
            <div className="warehouse_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Select
            options={warehouseOptions}
            getOptionLabel={(warehouse) => warehouse.name}
            getOptionValue={(warehouse) => warehouse.id}
            isSearchable
            placeholder={t('page_content.warehouse.warehouse_filter_placeholder')}
            onChange={(value) => this.handleQueryOrFilterChange('selectedWarehouse', value)}
            value={(warehouseOptions.find((whouse) => whouse.id === selectedWarehouse.id)) || ''}
            styles={selectStyles}
          />
          <div className="warehouse_clearFilters_button">
            <Button
              type="plain"
              onClick={this.clearFilters}
            >
              {t('page_content.events.events.clear_all_button')}
            </Button>
          </div>
          <div className="warehouse_exportExcel_button">
            <Button
              type="export"
              onClick={this.exportToExcel}
              loading={loadingExport}
            >{t('page_content.warehouse.export_to_excel_button')}</Button>
          </div>
        </div>
        {
          !selectedWarehouse && <span className="warehouse_warning">
            <IconWarning color={colors.red} height="16px" width="16px" />
            <p className="warehouse_warning_text">{t('page_content.warehouse.warning_placeholder')}</p>
          </span>
        }
        <div className="warehouse_table_area">
          <Table
            style={{ userSelect: 'text', overflow: 'auto' }}
            columns={[
              {
                Header: () => <span>{t('page_content.warehouse.table_column_warehouse')}</span>,
                accessor: 'warehouse.name',
                Cell: (row) => (row && row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_product')}</span>,
                sortable: false,
                Cell: (row) => (row?.original?.product_type ? row.original.product_type?.name ? row.original.product_type?.name : '-' : row?.original?.material_code || '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_product_code')}</span>,
                sortable: false,
                Cell: (row) => (row?.original?.product_type ? row.original.product_type?.code ? row.original.product_type?.code : '-' : row?.original?.material_code || '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_date')}</span>,
                accessor: 'created_at',
                Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_quantity')}</span>,
                accessor: 'quantity',
                Cell: (row) => <span>{row.original.unit_code && row.original.unit_code === 'PCE' ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : numberSeparatorFormat(i18n.language, row.value, 2, 2, true)}</span>,

              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_unit')}</span>,
                accessor: 'unit_code',
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_batch')}</span>,
                accessor: 'batch',
                Cell: (row) => (row && row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_expiration')}</span>,
                accessor: 'expiry_time',
                Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.table_column_status')}</span>,
                accessor: 'status',
                Cell: (row) => (row && row.value ? row.value : '-'),
              },
            ]}
            data={warehouseData}
            defaultPageSize={100}
            loading={isLoadingWarehouseData}
            minRows={0}
            noDataText=" "
            showPagination={false}
            sortable
            defaultSorted={[{ id: 'created_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchWarehouseDataByUrl} count={count} />
        </div>
      </div>
    );
  }
}

WarehouseStock.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    setCurrentUser: getCurrentUserSuccess,
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(WarehouseStock)));
