import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Table, Button, TableButtons } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import '../../../styles.scss';

import { getDataDefinitions, getPaginatedDataDefinitions, getPersonnelDataRatings, addPersonnelDataRating } from '../../../actions';

const PersonnelDataRating = ({ selectedWorker, currentUser, companyId, t }) => {
  const [personnelData, setPersonnelData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [additionalData, setAdditionalData] = useState({
    definitions: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [newScoring, setNewScoring] = useState({});
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'order',
    year: moment().format('YYYY'),
  });

  const fetchPersonnelDataRatings = async () => {
    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    let apiFilters = '&limit=9999';

    if (filters?.year) apiFilters += filters.year ? `&year=${filters.year}` : '';

    apiFilters += `&worker=${selectedWorker.id}`;

    await getPersonnelDataRatings(companyId, apiFilters)
      .then((res) => {
        const result = get(res, 'data.results', []);
        setPersonnelData({
          data: result,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setPersonnelData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const fetchDataDefinitions = async () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&is_active=true&order_by=${asc}${filters?.selectedSort}`;

    await getDataDefinitions(companyId, apiFilters)
      .then((res) => {
        setAdditionalData({
          definitions: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAdditionalData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const fetchPaginatedDataDefinitions = async (url) => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    await getPaginatedDataDefinitions(url)
      .then((res) => {
        setAdditionalData({
          definitions: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAdditionalData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  useEffect(() => {
    fetchDataDefinitions();
  }, [filters]);

  useEffect(() => {
    fetchPersonnelDataRatings();
  }, [additionalData]);

  const handleYearChange = (value) => {
    setFilters({ ...filters, year: value });
  };

  const handleChangeScoring = (key, value) => {
    if (!value) {
      setNewScoring((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    } else {
      setNewScoring((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleSubmitAll = () => {
    if (!selectedWorker?.id || !currentUser?.id || !newScoring || Object.keys(newScoring).length === 0) return;

    const data = Object.entries(newScoring).map(([key, value]) => ({
      definition: key,
      value: value?.label,
      // score: value?.value,
      worker: selectedWorker.id,
      user: currentUser.id,
      year: filters?.year ? moment(filters.year).format('YYYY') : null,
    }));

    // if (data.some((item) => !item.value || !item.score || !item.year || !item.definition)) return;
    if (data.some((item) => !item.value || !item.year || !item.definition)) return;

    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    Promise.all(data.map((item) => addPersonnelDataRating(companyId, item)))
      .then(() => { setNewScoring({}); fetchPersonnelDataRatings(); })
      .catch(() => { setPersonnelData((prevState) => ({ ...prevState, isLoading: false })); });
  };

  const addedScore = additionalData?.definitions?.map((definition) => {
    const score = personnelData?.data?.find((item) => item?.definition?.id === definition.id);
    return {
      ...definition,
      score: score?.score || '-',
      updatedAt: score?.updated_at || null,
      year: score?.year || '-',
      value: score?.value || '-',
    };
  });

  const totalScore = addedScore?.reduce((total, item) => total + (item.score === '-' ? 0 : item.score), 0);

  const columns = [
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_definition')}</span>,
      accessor: 'name',
      minWidth: 300,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_updated_at')}</span>,
      accessor: 'updatedAt',
      Cell: (row) => (row?.value ? moment(row.value)?.format(defaultDateFormat) : '-'),
      width: 150,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_year')}</span>,
      accessor: 'year',
      width: 100,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_value')}</span>,
      Footer: () => newScoring && Object.keys(newScoring).length > 0 &&
        <div style={{ width: '100%', display: 'flex' }}><Button style={{ marginLeft: 'auto' }} type="add" onClick={handleSubmitAll}>
          {t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_submit_all')}</Button></div>,
      accessor: 'scoring',
      Cell: (row) => {
        if (row?.value?.values && row?.original?.score === '-' && row?.original?.value === '-') {
          if (row?.value?.type === 'map') {
            const selectOptions = Object.entries(row?.value?.values)?.map(([key, value]) => ({ label: key, value }));
            const selectedValue = newScoring?.[row?.original?.id]?.value || null;
            return (
              <Select
                options={selectOptions || []}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                isClearable
                placeholder={t('page_content.human_resources.rating.personnel_data_tab.select_placeholder_value')}
                menuPosition="fixed"
                onChange={(e) => handleChangeScoring(row?.original?.id, e || '')}
                value={selectOptions?.find((val) => val.value === selectedValue) || null}
                styles={selectModalStyles}
              />
            );
          }
          if (row?.value?.type === 'range') {
            return (
              <input
                className="range_input"
                type="text"
                value={newScoring?.[row?.original?.id]?.value || ''}
                onChange={(e) => handleChangeScoring(row?.original?.id, e.target.value ? { value: e.target.value, label: e.target.value } : '')}
                placeholder={t('page_content.human_resources.rating.personnel_data_tab.input_placeholder_score')}
              />
            );
          }
        }
        return `${row?.original?.value || '-'}`;
      },
      minWidth: 300,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_score')}</span>,
      Footer: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_footer_total')}: {totalScore}</span>,
      accessor: 'score',
      width: 125,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },
    // {
    //   Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_submit')}</span>,
    //   Footer: () => <Button
    //     type="add"
    //     onClick={handleSubmitAll}
    //   >
    //     {t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_submit_all')}
    //   </Button>,
    //   width: 125,
    //   sortable: false,
    //   show: newScoring && Object.keys(newScoring).length > 0,
    //   style: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     cursor: 'default',
    //   },
    // },
  ];

  return (
    <div className="ratings__screen">
      <div className="ratings__screen__filters">
        <div className="double_box">
          <span style={{ fontSize: '13px', color: '#555', fontWeight: '700' }}>{t('page_content.human_resources.rating.personnel_data_tab.filter_by_year')}:</span>
          <DatePicker
            selected={filters?.year ? moment(filters?.year, 'YYYY').toDate() : moment().toDate()}
            onChange={(date) => handleYearChange(date ? moment(date).format('YYYY') : null)}
            placeholderText={t('YYYY')}
            showYearPicker
            dateFormat="yyyy"
            minDate={moment().toDate()}
            maxDate={moment().toDate()}
          />
        </div>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={columns}
        data={addedScore || []}
        defaultPageSize={15}
        loading={personnelData.isLoading}
        minRows={0}
        noDataText=""
        showPagination={false}
        selectedRow={null}
        defaultSorted={[{ id: 'order', desc: true }]}
        onSortedChange={(newSorted) => {
          setFilters({
            ...filters,
            selectedSort: newSorted[0].id,
            selectedAscDesc: newSorted[0].desc ? 'desc' : 'asc',
          });
        }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            previous={additionalData?.previous}
            next={additionalData?.next}
            fetchFunction={fetchPaginatedDataDefinitions}
            count={additionalData?.count}
          />
        </span>
      </div>
    </div>
  );
};

PersonnelDataRating.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(PersonnelDataRating));
