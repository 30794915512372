/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Table, Button, Modal } from 'shared';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { get, sortBy } from 'lodash';
import './style.scss';
import { addDepartmentTechnology, deleteDepartmentTechnology, editDepartmentTechnology, getDepartmentTechnologies, getPaginatedTechnologies, getTechnologyDepartments, addTechnologyStep, getTechnologySteps, deleteTechnologyStep } from './actions';
import TableButtons from '../../../../shared/TableButtons/index';
import TechnologyModal from './components/TechnologyModal/index';
import { selectStyles } from '../../../../styles/modules/reactSelect';

class TechnologyTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      isLoadingTechnology: true,
      isReadOnly: false,
      previous: null,
      showModal: false,
      next: null,
      technologies: [],
      departments: [],
      technologyData: {
        id: null,
        name: '',
        department: null,
        steps: Array(1).fill(''),
        availableDepartmentSteps: [],
        allDepartmentSteps: [],
      },
      deleteModalStatus: false,
      technologyModalRow: [],
      count: 0,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
          this.getTechnology();
          this.getDepartments();
        } else {
          this.getDepartments();
          this.getTechnology();
        }
      });
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.state.query !== prevState.query || this.props.companyId !== prevProp.companyId) {
      this.handleStateQueryChange();
    }
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getTechnology();
    });
  }

  getDepartments = () => {
    const { locationId } = this.props;

    this.setState({ isLoadingTechnology: true });

    getTechnologyDepartments(locationId, 9999)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingTechnology: false,
        });
      })
      .catch((error) => console.log(error));
  }

  getDepartmentNameById = (departmentId) => {
    const { departments } = this.state;
    const targetDepartment = departments.find((d) => d.id === departmentId);
    if (targetDepartment) {
      return targetDepartment.name;
    }
    return '';
  }

  getTechnology = () => {
    const { locationId } = this.props;
    const { query, selectedAscDesc, selectedSort } = this.state;

    let apiFilters = '';
    if (query) {
      apiFilters += query;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;
    getDepartmentTechnologies(locationId, apiFilters)
      .then((re) => {
        this.setState({
          technologies: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingTechnology: false,
        });
      })
      .catch((error) => console.log(error));
  }

  handleChange = (value) => {
    this.setState({ query: value });
  }

  handleStateQueryChange = () => {
    const { query, selectedAscDesc, selectedSort } = this.state;
    const { locationId } = this.props;
    this.setState({
      isLoadingTechnology: true,
    });

    let apiFilters = '';
    if (query) {
      apiFilters += query;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;

    getDepartmentTechnologies(locationId, apiFilters)
      .then((re) => this.setState({
        technologies: get(re, 'data.results'),
        isLoadingTechnology: false,
      }))
      .catch((error) => console.log(error));
  }

  handleClearSearch = () => {
    this.setState({ query: null });
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
      technologyData: {
        id: null,
        name: '',
        department: null,
        steps: Array(1).fill(''),
        availableDepartmentSteps: [],
      },
    });
  }

  handleEditDepartmentTechnology = (row) => {
    const selectedDepartment = this.state.departments.filter((dep) => dep.id === row.original.department);
    const departmentSteps = selectedDepartment[0].steps;
    const technologySteps = row.original.steps;
    let availableDepartmentSteps = Object.keys(departmentSteps).length > 0 && departmentSteps.steps ? departmentSteps.steps : [];
    if (technologySteps.steps) {
      availableDepartmentSteps = availableDepartmentSteps.filter((step) => {
        return !technologySteps.steps.some((techStep) => techStep.operation === step.id);
      });
    }
    this.setState({
      technologyData: {
        id: row.original.id,
        name: row.original.name,
        department: row.original.department,
        steps: Object.keys(technologySteps).length > 0 && technologySteps.steps ? technologySteps.steps : Array(1).fill(''),
        allDepartmentSteps: Object.keys(departmentSteps).length > 0 && departmentSteps.steps ? departmentSteps.steps : [],
        availableDepartmentSteps,
      },
    }, () => {
      this.setState({
        showModal: true,
      });
    });
  }

  handleTechnologyData = (value, field) => {
    const { technologyData, departments } = this.state;
    const newTechnologyData = { ...technologyData };
    newTechnologyData[field] = value;
    if (field === 'department') {
      const selectedDepartment = departments.filter((dep) => dep.id === newTechnologyData.department);
      const allDepartmentStepsObject = selectedDepartment[0].steps;
      const allDepartmentSteps = Object.keys(allDepartmentStepsObject).length > 0 && allDepartmentStepsObject.steps ? allDepartmentStepsObject.steps : [];
      const availableDepartmentSteps = [...allDepartmentSteps];
      this.setState({
        technologyData: {
          ...newTechnologyData,
          steps: Array(1).fill(''),
          allDepartmentSteps,
          availableDepartmentSteps,
        },
      });
    } else {
      this.setState({
        technologyData: newTechnologyData,
      });
    }
  }

  handleCreateOrUpdateTechnology = async () => {
    const { technologyData } = this.state;
    const { locationId } = this.props;

    if (!technologyData) return;
    this.setState({
      isLoadingTechnology: true,
      showModal: false,
    });

    const payload = {
      id: technologyData.id ? technologyData.id : '',
      name: technologyData.name,
      department: technologyData.department,
      steps: { steps: technologyData.steps },
    };

    if (technologyData.id) {
      const steps = payload?.steps?.steps.filter((step) => step && typeof step === 'object');

      // Step 1: Filter steps where the 'operation' key is not present
      let newArray = steps.filter((item) => !Object.hasOwn(item, 'operation'));

      // Step 2: Get technology steps from API
      const res = await getTechnologySteps(technologyData.id);
      const existingSteps = res.data.results;

      // Step 3: Filter newArray to include only items that are not found in existingSteps based on operation
      newArray = newArray.filter((newItem) => !existingSteps.some((item) => newItem.id === item.operation));

      // Step 4: Create technology steps and keep track of their indices in the original steps array
      if (newArray.length > 0) {
        const stepPromises = newArray.map(async (step) => {
          const index = steps.findIndex((s) => s.id === step.id);
          const response = await addTechnologyStep(technologyData.id, { operation: step.id, order: index, technology: technologyData.id });
          return { response: get(response, 'data', null), index };
        });
        const newStepsResponses = await Promise.all(stepPromises);

        // Step 5: Replace the corresponding objects in steps with the newly created steps
        newStepsResponses.forEach(({ response, index }) => {
          steps[index] = response;
        });
      }

      // Step 6: Remove items from newArray if they exist in existingSteps and have the same order, and replace them with items from existingSteps
      existingSteps.forEach((existingStep) => {
        const index = steps.findIndex((step) => step.id === existingStep.operation && step.order === existingStep.order);
        if (index !== -1) {
          newArray = newArray.filter((item) => item.id !== existingStep.operation);
          steps[index] = existingStep;
        }
      });

      // Step 7: Ensure all elements are in the correct positions in the steps array
      newArray.forEach((item) => {
        const index = steps.findIndex((step) => step.id === item.id);
        if (index !== -1) {
          steps[index] = item;
        }
      });

      // Merge existingSteps and steps, ensuring correct indexes
      steps.forEach((step, index) => {
        const existingStep = existingSteps.find((es) => es.order === index);
        if (existingStep) {
          steps[index] = existingStep;
        }
      });

      editDepartmentTechnology(technologyData.id, locationId, { ...payload, steps: { steps } })
        .then(() => {
          this.setState({
            showModal: false,
            technologyData: {
              id: null,
              name: '',
              department: null,
              steps: [],
              availableDepartmentSteps: [],
              allDepartmentSteps: [],
            },
          });
          this.getTechnology();
        });
    } else {
      const steps = payload.steps.steps;
      addDepartmentTechnology(payload)
        .then(async (res) => {
          const techId = get(res, 'data.id', null);
          let newSteps = [];

          if (techId) {
            // eslint-disable-next-line no-return-await
            const stepPromises = steps.map(async (step, index) => await addTechnologyStep(techId, { operation: step.id, order: index, technology: techId }));
            const newStepsResponses = await Promise.all(stepPromises);
            newSteps = newStepsResponses.map((stepResponse) => get(stepResponse, 'data', null));
          }

          await editDepartmentTechnology(techId, locationId, { ...get(res, 'data'), steps: { steps: newSteps } })
            .then(() => {
              this.setState({
                showModal: false,
                technologyData: {
                  id: null,
                  name: '',
                  department: null,
                  steps: [],
                  availableDepartmentSteps: [],
                  allDepartmentSteps: [],
                },
              });
              this.getTechnology();
            });
        });
    }
  }

  handleDeleteTechnology = (id) => {
    const { technologies } = this.state;
    const { locationId } = this.props;
    if (!technologies && !technologies.id) return;
    this.setState({
      isLoadingTechnology: true,
    });

    deleteDepartmentTechnology(id, locationId)
      .then(() => {
        this.setState({
          deleteModalStatus: false,
          technologyModalRow: [],
        }, () => this.getTechnology());
      });
  }

  fetchDataForPagination = (url) => {
    this.setState({
      isLoadingTechnology: true,
    });

    getPaginatedTechnologies(url)
      .then((re) => {
        this.setState({
          technologies: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingTechnology: false,
        });
      })
      .catch((e) => console.error('Error while fetching technologies', e));
  }

  handleDeleteModalOpen = (e, rowInfo) => {
    e.stopPropagation();
    this.setState({
      deleteModalStatus: true,
      technologyModalRow: rowInfo,
    });
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteModalStatus: false,
      technologyModalRow: [],
    });
  }

  handleStepJsonChange = (value, index) => {
    const { allDepartmentSteps } = this.state.technologyData;
    const newSteps = [...this.state.technologyData.steps];
    newSteps[index] = value;
    const newAvailableSteps = allDepartmentSteps.filter((step) => {
      return !newSteps.some((techStep) => techStep.operation === step.id || techStep.id === step.id);
    });

    this.setState((prevState) => ({
      technologyData: {
        ...prevState.technologyData,
        availableDepartmentSteps: newAvailableSteps,
        steps: newSteps,
      },
    }));
  };

  removeStepValue = async (row, index) => {
    const { steps, allDepartmentSteps } = this.state.technologyData;
    const { technologyData } = this.state;
    const { locationId } = this.props;

    if (row?.original?.operation) await deleteTechnologyStep(index.id, index.technology);
    const newSelectedSteps = [...steps];
    newSelectedSteps[index] = '';
    const newAvailableSteps = allDepartmentSteps.filter((step) => {
      return !newSelectedSteps.some((techStep) => techStep.operation === step.id || techStep.id === step.id);
    });

    newSelectedSteps.pop();

    const payload = {
      id: technologyData.id ? technologyData.id : '',
      name: technologyData.name,
      department: technologyData.department,
      steps: { steps: newSelectedSteps },
    };
    if (row?.original?.operation) await editDepartmentTechnology(index.technology, locationId, payload);

    this.setState((prevState) => ({
      technologyData: {
        ...prevState.technologyData,
        availableDepartmentSteps: newAvailableSteps,
        steps: newSelectedSteps,
      },
    }));
  }

  handleInsertNewEmptyStep = () => {
    const { steps, allDepartmentSteps } = this.state.technologyData;
    const newAvailableSteps = allDepartmentSteps.filter((step) => {
      return !steps.some((techStep) => techStep.operation === step.id || techStep.id === step.id);
    });
    this.setState((prevState) => ({
      technologyData: {
        ...prevState.technologyData,
        steps: [...prevState.technologyData.steps, ''],
        availableDepartmentSteps: newAvailableSteps,
      },
    }));
  };

  render() {
    const { isLoadingTechnology, isReadOnly, previous, next, technologyData, showModal, technologies, departments, query, deleteModalStatus, technologyModalRow, count } = this.state;
    const { availableDepartmentSteps } = this.state.technologyData;
    const { t } = this.props;
    const defaultSelectValues = [{ id: null, name: t('page_content.technology.select_placeholder') }, ...departments];

    const deleteModalColumnInfo = [
      {
        Header: () => <span>{t('page_content.technology.table_column_name')}</span>,
        accessor: 'name',
      },
      {
        Header: () => <span>{t('page_content.technology.table_column_department')}</span>,
        accessor: 'department',
        Cell: (row) => <div>{this.getDepartmentNameById(row.original.department)}</div>,
      },
    ];

    let maxSteps = 0;
    technologies.forEach((tech) => {
      if (tech?.steps?.steps?.length > maxSteps) {
        maxSteps = tech.steps.steps.length;
      }
    });

    const dynamicColumns = Array.from({ length: maxSteps }, (_, index) => ({
      Header: () => <span>{`${t('page_content.projects.products_tab.table_column_step')} ${index + 1}`}</span>,
      accessor: 'steps',
      Cell: (row) => {
        const steps = row?.value?.steps || [];
        const operations = get(row, 'original.operations') || [];
        const stepOperationId = steps[index]?.operation;
        const operation = operations.find((op) => op.id === stepOperationId);
        return (
          <div>
            {operation ? operation.name : '-'}
          </div>
        );
      },
      sortable: false,
    }));

    return (
      <div className="department-technology-management-list">
        <div className="table-controls">
          <div className="table-controls__search">
            <div className="table-select">
              <Select
                options={sortBy(defaultSelectValues, [(value) => value.name.toLowerCase()])}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t('page_content.technology.select_placeholder')}
                onChange={(value) => { this.handleChange(value.id, 'department'); }}
                value={defaultSelectValues.find((d) => d.id === query)}
                styles={selectStyles}
              />
              <Button onClick={this.handleClearSearch}>
                {t('shared.clear_button')}
              </Button>
            </div>
            <Button
              onClick={() => this.setState({
                showModal: true,
              })}
              disabled={isReadOnly}
              style={{ height: 'fit-content' }}
              type="add"
            >
              {t('page_content.technology.add_technology')}
            </Button>
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.technology.table_column_name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => <span>{t('page_content.technology.table_column_department')}</span>,
              accessor: 'department',
              Cell: (row) => <div>{this.getDepartmentNameById(row.original.department)}</div>,
            },
            ...dynamicColumns,
            {
              Header: () => <span>{t('page_content.technology.table_column_delete')}</span>,
              accessor: 'id',
              width: 150,
              sortable: false,
              Cell: (row) => <Button disabled={isReadOnly} onClick={(e) => this.handleDeleteModalOpen(e, row.original)} style={{ zIndex: 0 }}>{t('page_content.technology.delete_button')}</Button>,
            },
          ]}
          data={technologies || []}
          defaultPageSize={30}
          loading={isLoadingTechnology}
          handleClick={(rowInfo) => (isReadOnly ? null : this.handleEditDepartmentTechnology(rowInfo))}
          minRows={0}
          noDataText=""
          showPagination={false}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <span style={{ float: 'right' }}>
          <TableButtons previous={previous} next={next} fetchFunction={this.fetchDataForPagination} count={count} />
        </span>
        <TechnologyModal
          modalState={showModal}
          handleModalClose={this.handleModalClose}
          technology={technologyData}
          departments={departments}
          handletechnologyData={this.handleTechnologyData}
          handleCreateOrUpdateTechnology={this.handleCreateOrUpdateTechnology}
          handleDeleteTechnology={this.handleDeleteTechnology}
          title={technologyData.id ? t('page_content.technology.edit_technology_button') : t('page_content.technology.add_technology_button')}
          t={t}
          availableDepartmentSteps={availableDepartmentSteps}
          handleStepJsonChange={this.handleStepJsonChange}
          removeStepValue={this.removeStepValue}
          handleInsertNewEmptyStep={this.handleInsertNewEmptyStep}
        />
        <Modal isOpen={deleteModalStatus} title={t('page_content.technology.table_column_delete')} handleDeletePosition="footer" handleDelete={() => this.handleDeleteTechnology(technologyModalRow.id)} handleClose={() => this.handleDeleteModalClose()}>
          <Table
            style={{ userSelect: 'text' }}
            columns={deleteModalColumnInfo}
            data={[technologyModalRow] || []}
            defaultPageSize={1}
            loading={isLoadingTechnology}
            minRows={0}
            noDataText=""
            showPagination={false}
            sortable={false}
          />
        </Modal>
      </div>
    );
  }
}
TechnologyTab.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
};

export default (withRouter(withTranslation()(TechnologyTab)));
