import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line
export const getOrders = async (url) => {
  try {
    const res = await api
      .get(`${url}&include_lines=true&limit=30&is_deleted=false`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const patchOrders = async (orderId, data) => {
  return api.patch(`/api/v1/orders/orders/${orderId}/`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Archive successful', '');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred while archiving an order', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getOrderStatusHistory = async (orderId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/status_history/?order=${orderId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order status history', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLocations = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/locations/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderTypes = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_types/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}&fat=1&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLines = async (company_short_code, location = '') => {
  try {
    const res = await api
      .get(`/api/v1/planning/lines/?company=${company_short_code}&location=${location}&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching lines', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductTypes = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/product_types/?company=${companyId}&limit=999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const createOrder = async (data) => {
  try {
    const res = await api
      .post('/api/v1/orders/batch_create/', data);
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while creating order', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const createOrderFromItems = (data) => {
  return api.post('/api/v1/orders/project_products_order/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Order was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const generateExternalId = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/next_external_id/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.next_external_id) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while generating external id', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPartners = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/partners/partners?company=${companyId}&limit=9999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching partners', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderItemsList = async (companyId, filters) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_items/?company=${companyId}${filters}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedOrderItemsList = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order items list data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartments = async (locationId) => {
  const urlString = '/api/v1/departments/';
  let urlParams = '?limit=30';

  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartmentTechnologies = async (locationId, departmentId) => {
  const urlString = '/api/v1/department_technologies/';
  let urlParams = '?limit=30';

  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (departmentId) {
    urlParams += `&department=${departmentId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching department technologies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getCompanyMembers = async (companyId) => {
  const urlString = '/api/v1/company_members/';
  let urlParams = '?limit=9999';

  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching company members', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getReferentMembers = async (companyId) => {
  const urlString = '/api/v1/orders/order_users_select/';
  let urlParams = '?limit=9999';

  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching company referent members', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjects = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/projects/projects/?company=${companyId}&location=${locationId}&limit=9999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllDepartments = async (locationId) => {
  const url = `/api/v1/departments/?location=${locationId}&limit=9999`;

  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllTechnologies = async (locationId) => {
  const url = `/api/v1/department_technologies/?location=${locationId}&limit=9999`;

  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching technologies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllBookings = async (locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_bookings/?location=${locationId}${filters}&limit=30&is_archived=false`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching bookings', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedAllBookings = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching bookings', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getShifts = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/shifts/shifts/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
