import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Table, Button, ConfirmationModal } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { defaultDateFormat } from 'shared/constants';
import { IconRemove } from 'shared/Icons';
import { deleteWorkerSalary, getSalaries, getWorkerSchedules } from '../../actions';

import SalaryModal from './components/SalaryModal';
import './styles.scss';

const GeneralInfoTab = ({ t, companyId, workerDetails, transformString, isReadOnly, currentUserRole }) => {
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const [salaries, setSalaries] = useState({
    data: [],
    isLoading: true,
  });

  const [sickLeave, setSickLeave] = useState({
    data: [],
    isLoading: true,
  });
  const [dateFilter, setDateFilter] = useState({
    start: moment().add(-1, 'months'),
    end: moment(),
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const getGenderLabel = () => {
    let label = '-';
    if (workerDetails?.gender === 'male') {
      label = t('page_content.workforce_management.worker_details.gender_male');
    }
    if (workerDetails?.gender === 'female') {
      label = t('page_content.workforce_management.worker_details.gender_female');
    }
    if (workerDetails?.gender === 'other') {
      label = t('page_content.workforce_management.worker_details.gender_other');
    }
    return label;
  };

  const fetchSalaries = () => {
    if (workerDetails?.id && currentUserRole !== 'Voditelj') {
      setSalaries((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const apiFilters = `&limit=100&order_by=-date&worker=${workerDetails?.id}`;

      getSalaries(companyId, apiFilters)
        .then((res) => {
          setSalaries({
            data: get(res, 'data.results', []),
            isLoading: false,
          });
        })
        .catch(() => {
          setSalaries((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        });
    }
  };

  const fetchSickLeave = () => {
    if (workerDetails?.id) {
      setSickLeave((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      let apiFilters = `&order_by=-date&worker=${workerDetails?.id}&day_type=sick,long-sick`;

      if (dateFilter?.start) {
        apiFilters += `&date_after=${moment(dateFilter.start).format('YYYY-MM-DD')}`;
      }
      if (dateFilter?.start && dateFilter?.end) {
        apiFilters += `&date_before=${moment(dateFilter.end).format('YYYY-MM-DD')}`;
      }

      getWorkerSchedules(companyId, apiFilters)
        .then((res) => {
          setSickLeave({
            data: get(res, 'data.results', []),
            isLoading: false,
          });
        })
        .catch(() => {
          setSickLeave((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        });
    }
  };

  useEffect(() => {
    fetchSalaries();
  }, [workerDetails]);

  useEffect(() => {
    fetchSickLeave();
  }, [workerDetails, dateFilter]);

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const handleAddNewSalary = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchSalaries();
  };

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteWorkerSalary(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchSalaries();
  };

  const totalSickLeave = sickLeave?.data?.reduce((acc, item) => {
    if (item?.day_type === 'sick' || item?.day_type === 'long-sick') {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <div className="workerDetails_generalInfoTable">
      <div className={currentUserRole !== 'Voditelj' ? 'general_info_table' : 'general_info_table_50'}>
        <div className="header">{t('page_content.workforce_management.worker_details.general_info')}</div>
        <div className="info_table_content">
          <div className="row">{t('page_content.workforce_management.worker_details.general_full_name')}:<span>{workerDetails ? `${transformString(workerDetails.name)} ${transformString(workerDetails.last_name)}` : '-'}</span></div>
          <div className="row">{t('page_content.workforce_management.worker_details.general_gender')}:<span>{getGenderLabel()}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_age_years')}:<span>{workerDetails?.date_of_birth ? moment().diff(moment(workerDetails?.date_of_birth), 'years') : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_birthday')}:<span>{workerDetails?.date_of_birth ? moment(workerDetails.date_of_birth).format(defaultDateFormat) : '-'}</span></div>
          <div className="row">{t('page_content.workforce_management.worker_details.general_email')}:<span>{workerDetails?.email || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_private_email')}:<span>{workerDetails?.private_email || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_mobile')}: <span>{workerDetails?.mobile_phone || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_tax')}: <span>{workerDetails?.tax_id || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_address')}:<span>{workerDetails?.address || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_qualifications')}:<span>{workerDetails?.qualification?.name || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_profession')}:<span>{workerDetails?.profession?.name || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_department')}: <span>{workerDetails?.department ? workerDetails.department : workerDetails?.location_department ? workerDetails?.location_department?.name : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.default_shift')}:<span>{workerDetails?.department_shift ? `${workerDetails?.department_shift?.name} (${moment(workerDetails?.department_shift?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(workerDetails?.department_shift?.end_time, 'HH:mm:ss').format('HH:mm')})` : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_active_from')}:<span>{workerDetails?.active_from ? moment(workerDetails.active_from).format(defaultDateFormat) : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_total_service')}:<span>{workerDetails?.total_service || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_kfk_service')}:<span>{workerDetails?.kfk_service || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_transport_amount')}:<span>{workerDetails?.transport_fee || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_food_amount')}:<span>{workerDetails?.food_fee || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_work_permit')}:<span>{workerDetails?.work_permit_valid_until ? moment(workerDetails?.work_permit_valid_until).format(defaultDateFormat) : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_info')}:<span>{workerDetails?.note || '-'}</span></div>
        </div>
      </div>
      {
        currentUserRole !== 'Voditelj' &&
        <div className="salary_table">
        <div className="header">
          <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_salary')}</span>
          <Button
            disabled={isReadOnly}
            type="add"
            onClick={handleAddNewSalary}
          >
            {t('page_content.human_resources.workers.salary_increase_table.add_salary')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_date')}</span>,
              accessor: 'date',
              width: 110,
              Cell: (row) => <span>{row.value ? moment(row.value).format(defaultDateFormat) : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_net_salary')}</span>,
              accessor: 'net_salary',
              width: 110,
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_gross_salary')}</span>,
              accessor: 'gross_salary',
              width: 110,
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_increase')}</span>,
              accessor: 'percent',
              Cell: (row) => <span style={{ color: parseFloat(row?.value) > 0 ? 'green' : 'red' }}>
                {row.value ? row.value : '-'}
              </span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 80,
              sortable: false,
            },
          ]}
          isCompact
          data={salaries?.data}
          minRows={0}
          selectedRow={null}
          defaultPageSize={100}
          noDataText=" "
          showPagination={false}
          sortable={false}
          loading={salaries?.isLoading}
        />
      </div>
      }

      <div className={currentUserRole !== 'Voditelj' ? 'sick_table' : 'sick_table_50'}>
        <div className="header">
          <span>{t('page_content.human_resources.workers.sick_leave')}</span>

          <div className="datepicker_container">
            <div className="datepicker_wrapper">
              <ReactDatePicker
                placeholderText=""
                dateFormat="dd.MM.yyyy"
                selected={dateFilter?.start ? moment(dateFilter?.start).toDate() : null}
                maxDate={dateFilter?.end ? moment(dateFilter?.end).toDate() : null}
                selectsStart
                onChange={(e) => setDateFilter({ ...dateFilter, start: e })}
                showTimeSelect={false}
                disabledKeyboardNavigation
                locale={getLocale(t)}
              />
            </div>

            <span> - </span>

            <div className="datepicker_wrapper">
              <ReactDatePicker
                placeholderText=""
                dateFormat="dd.MM.yyyy"
                selected={dateFilter?.end ? moment(dateFilter?.end).toDate() : null}
                minDate={dateFilter?.start ? moment(dateFilter?.start).toDate() : null}
                selectsEnd
                onChange={(e) => setDateFilter({ ...dateFilter, end: e })}
                showTimeSelect={false}
                disabledKeyboardNavigation
                locale={getLocale(t)}
              />
            </div>
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_date')}</span>,
              Footer: () => <span>{t('page_content.human_resources.workers.total_sick_leave_days')}: {totalSickLeave}</span>,
              accessor: 'date',
              Cell: (row) => <span>{row.value ? moment(row.value).format(defaultDateFormat) : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.day_type')}</span>,
              Footer: () => <span>{t('page_content.human_resources.workers.total_sick_leave_hours')}: {totalSickLeave * 8}h</span>,
              accessor: 'day_type',
              Cell: (row) => <span>{row?.value ? t(`page_content.human_resources.workers.${row?.value}`) : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
          ]}
          isCompact
          data={sickLeave?.data}
          minRows={0}
          selectedRow={null}
          defaultPageSize={100}
          noDataText=" "
          showPagination={false}
          sortable={false}
          loading={sickLeave?.isLoading}
        />
      </div>

      {modalData?.isOpen &&
        <SalaryModal
          workerDetails={workerDetails}
          isOpen={modalData?.isOpen}
          initialValues={modalData?.selectedItem}
          handleCloseModal={handleCloseModal}
          closeModalAndRefetch={closeModalAndRefetch}
        />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'original.date', '')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

GeneralInfoTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  workerDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  transformString: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  currentUserRole: PropTypes.string,
};

export default (withTranslation()(GeneralInfoTab));
