import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import Select from 'react-select';
import { Modal, Table, Button } from 'shared';
import { withTranslation } from 'react-i18next';
import { ReactTableDefaults } from 'react-table';
import { selectModalStyles } from '../../../../../../styles/modules/reactSelect';

const TechnologyModal = (props) => {
  const [missingFields, setMissingFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const {
    handleModalClose,
    modalState,
    handletechnologyData,
    technology,
    departments,
    handleCreateOrUpdateTechnology,
    title,
    t,
    availableDepartmentSteps,
    handleStepJsonChange,
    removeStepValue,
    handleInsertNewEmptyStep,
  } = props;

  const technologySteps = technology.steps;
  const stepsColumnDefinition = [
    {
      Header: () => <span className="step_modal_input">#</span>,
      width: 75,
      Cell: (row) => <span>{row.index + 1}</span>,
    },
    {
      Header: () => {
        return (
          <div className="step_table_header_wrapper">
            <div>{t('page_content.projects.products_tab.steps_modal.table_column_stepName')}</div>
            <div className="add_new_button">
              <Button type="add" onClick={() => handleInsertNewEmptyStep()}>{t('page_content.department.modal_new_step_button')}</Button>
            </div>
          </div>
        );
      },
      Cell: (row) => (<span className="step_modal_input">
        <Select
          isDisabled={availableDepartmentSteps.length === 0}
          options={availableDepartmentSteps}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={`${t('page_content.projects.products_tab.table_column_step')} ${row.index + 1}`}
          onChange={(value) => { handleStepJsonChange(value, row.index); }}
          value={technology?.allDepartmentSteps?.find((depSteps) => depSteps.id === technology?.steps?.find((step) => step?.order === row?.index)?.operation) ||
            technology?.steps[row?.index] || ''}
          styles={selectModalStyles}
          menuPosition="fixed"
        />
      </span>),
    },
    {
      Header: () => <span>{t('page_content.technology.modal_remove_step')}</span>,
      width: 150,
      Cell: (row) => <Button type="delete" onClick={() => removeStepValue(row, technology?.steps?.find((step) => step?.order === row?.index) || row?.index)}>{t('page_content.technology.modal_remove_step_button')}</Button>,
    },
  ];

  const stepColumnDefaults = { ...ReactTableDefaults.column, headerClassName: 'step_table_override_header', className: 'step_table_override' };

  useEffect(() => {
    if (Object.values(missingFields).some((element) => element === true) || !submitted) {
      return;
    }
    handleCreateOrUpdateTechnology();
  }, [missingFields, submitted]);

  return (
    <Modal
      isOpen={modalState}
      handleClose={() => {
        handleModalClose();
      }}
      handleSave={() => {
        setMissingFields((prevState) => ({ ...prevState, name: !technology.name, department: !technology.department }));
        setSubmitted(true);
      }}
      title={title}
      disabled
    >
      <form className="default-form department-technology-details-form">
        <table>
          <tbody>
            <tr>
              <td className="label">
                {`${t('page_content.technology.table_column_name')} *`}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handletechnologyData(e.target.value, 'name'); }}
                  value={technology.name}
                  style={missingFields.name ? { borderColor: 'red' } : {}}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.technology.table_column_department')}
              </td>
              <td className={`input ${missingFields.department ? 'warning' : 'success'}`}>
                <Select
                  options={sortBy(departments, [(value) => value.name.toLowerCase()])}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.technology.select_placeholder')}
                  onChange={(value) => { handletechnologyData(value.id, 'department'); }}
                  value={departments.find((d) => d.id === technology.department)}
                  styles={selectModalStyles}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <Table
        style={{ userSelect: 'text', background: 'white' }}
        columns={stepsColumnDefinition}
        data={technologySteps}
        defaultPageSize={10}
        column={stepColumnDefaults}
        minRows={0}
        noDataText=""
        showPagination={technology.steps.length > 10}
        sortable={false}
      />
    </Modal>
  );
};

TechnologyModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  handletechnologyData: PropTypes.func.isRequired,
  technology: PropTypes.object.isRequired,
  departments: PropTypes.array,
  handleCreateOrUpdateTechnology: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  availableDepartmentSteps: PropTypes.array.isRequired,
  handleStepJsonChange: PropTypes.func.isRequired,
  removeStepValue: PropTypes.func.isRequired,
  handleInsertNewEmptyStep: PropTypes.func,
};

export default withTranslation()(TechnologyModal);
