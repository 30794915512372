import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { numberSeparatorFormat } from 'industry/helpers';
import { getBatchData } from '../../../actions';

class EditBatchAndWarehouseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWarehouse: props.selectedBookingItem && props.selectedBookingItem.warehouse ? props.selectedBookingItem.warehouse : '',
      selectedBatch: props.selectedBookingItem && props.selectedBookingItem.batch ? props.selectedBookingItem.batch : '',
      formError: false,
      batchList: [],
    };
  }

  componentDidMount() {
    const { companyId, selectedBookingItem, order } = this.props;

    let material_code = selectedBookingItem && selectedBookingItem.material_code ? selectedBookingItem.material_code : '';
    const isStavke = !!(selectedBookingItem && selectedBookingItem.order_item && selectedBookingItem.order_item.group_name && selectedBookingItem.order_item.group_name === 'Stavke');

    if (!isStavke) {
      if (!material_code) {
        const name = selectedBookingItem && selectedBookingItem.order_item && selectedBookingItem.order_item.name ? selectedBookingItem.order_item.name : '';
        const words = name.split(' ');
        const firstWord = words[0];

        material_code = firstWord;
      }

      let batchFilters = '';
      if (order && order.id) {
        batchFilters += `&order=${order.id}`;
      }

      getBatchData(companyId, material_code, batchFilters)
        .then((res) => {
          const { selectedBatch } = this.state;
          const batchList = res.data.results || [];
          const isBatchExists = batchList.some((batch) => batch.batch === selectedBatch);

          this.setState({
            batchList,
            selectedBatch: isBatchExists ? selectedBatch : '', // Keep the selection if it exists, otherwise reset it.
          });
        });
    }
  }

  onChangeWarehouse = (e) => {
    this.setState({
      selectedWarehouse: e.target.value || null,
    });
  }

  onChangeBatch = (e) => {
    const { batchList } = this.state;

    const value = e.target.value;
    const foundOption = batchList.find((option) => option.batch === value);

    const warehouseValue = foundOption.warehouse.code || null;

    this.setState({
      selectedBatch: value || '',
      selectedWarehouse: warehouseValue,
    });
  }

  onChangeBatchTextInput = (e) => {
    const value = e.target.value;
    this.setState({
      selectedBatch: value,
    });
  }

  onSaveBatchOrWarehouse = (isStavke) => {
    const { selectedBatch, selectedWarehouse } = this.state;
    const { onSaveBatchOrWarehouse, selectedBookingItem } = this.props;

    if ((!selectedBatch || !selectedWarehouse) && !isStavke) {
      this.setState({
        formError: true,
      });
      return '';
    }

    const orderItemBookingId = selectedBookingItem && selectedBookingItem.id
      ? selectedBookingItem.id : null;

    const data = {
      batch: selectedBatch || '',
      warehouse: selectedWarehouse,
    };

    onSaveBatchOrWarehouse(orderItemBookingId, data);

    this.setState({
      selectedWarehouse: '',
      selectedBatch: '',
    });
  }

  getCellValue = (value, unit_code) => {
    const { i18n } = this.props;

    const unitCode = unit_code || null;
    const cellValue = value || null;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, cellValue, 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, cellValue, 3, 3, true);
  }

  closeEditBatchAndWarehouseModal = () => {
    const { closeEditBatchAndWarehouseModal } = this.props;

    this.setState({
      selectedWarehouse: '',
      selectedBatch: '',
    }, closeEditBatchAndWarehouseModal());
  }

  render() {
    const { selectedWarehouse, selectedBatch, formError, batchList } = this.state;

    const {
      t,
      isEditBatchAndWarehouseModalOpen,
      selectedBookingItem,
      listOfWarehouses,
      selectedBookingItemRow,
      enable_book_receipt,
    } = this.props;

    const isStavke = !!(selectedBookingItem && selectedBookingItem.order_item && selectedBookingItem.order_item.group_name && selectedBookingItem.order_item.group_name === 'Stavke');

    return (
      <Modal
        isOpen={isEditBatchAndWarehouseModalOpen}
        handleSave={() => this.onSaveBatchOrWarehouse(isStavke)}
        handleClose={this.closeEditBatchAndWarehouseModal}
        error={formError && !isStavke ? t('page_content.orders.order_details.bookings_tab.warehouse_form_error') : ''}
        title={selectedBookingItem && selectedBookingItem.order_item && selectedBookingItem.order_item.name ? selectedBookingItem.order_item.name : ''}
      >
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('page_content.orders.order_details.bookings_tab.table_column_batch')}
                </td>
                <td className="value">
                  {
                    isStavke ?
                      <input
                        style={{ width: '75%' }}
                        onChange={(e) => this.onChangeBatchTextInput(e)}
                        type="text"
                        value={selectedBatch}
                      /> :
                      <select
                        onChange={(e) => this.onChangeBatch(e)}
                        value={selectedBatch || ''}
                        className="filters_warehouse_dropdown"
                      >
                        <option value="" disabled>-</option>
                        {batchList.map((batch) => (
                          <option key={batch.id} value={batch.batch}>
                            {batch.batch} ({this.getCellValue(batch.quantity, batch.unit_code)} {batch.unit_code})({batch.warehouse.name})
                          </option>
                        ))}
                      </select>
                  }
                </td>
              </tr>

              {
              (!isStavke || (selectedBookingItemRow.index === 0 && enable_book_receipt)) &&
                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.bookings_tab.table_column_warehouse')}
                  </td>
                  <td className="value">
                    <select
                      onChange={(e) => this.onChangeWarehouse(e)}
                      value={selectedWarehouse}
                      className="filters_warehouse_dropdown"
                    >
                      <option value="" disabled>-</option>
                      {listOfWarehouses.map((warehouse) => (
                        <option key={warehouse.id} value={warehouse.code}>
                          {warehouse.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

EditBatchAndWarehouseModal.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  isEditBatchAndWarehouseModalOpen: PropTypes.bool.isRequired,
  selectedBookingItem: PropTypes.object.isRequired,
  listOfWarehouses: PropTypes.array.isRequired,
  closeEditBatchAndWarehouseModal: PropTypes.func.isRequired,
  onSaveBatchOrWarehouse: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  order: PropTypes.object.isRequired,
  selectedBookingItemRow: PropTypes.object,
  enable_book_receipt: PropTypes.bool,
};

export default withTranslation()(EditBatchAndWarehouseModal);
