import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Button, Table, Notification, TableButtons, ConfirmationModal } from 'shared';
import { IconSearch } from 'shared/Icons';
import {
  getProductTypes,
  saveProductType,
  editProductType,
  getPaginatedProductTypes,
  deleteProductType,
  getProductDepartments,
  getProductTypeUnits,
  getProductDataTemplate,
} from './actions';
import ProductTypeForm from './components/ProductTypeForm/index';
import './style.scss';

class ProductTypesTab extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      isProductFormOpen: false,
      selectedProductType: null,
      isLoadingProducts: true,
      productTypes: [],
      productDepartments: [],
      productTypeUnits: [],
      productDataTemplate: {},
      isSavingProduct: false,
      next: null,
      previous: null,
      productCodeQuery: '',
      productNameQuery: '',
      count: 0,
      selectedSort: 'name',
      selectedAscDesc: 'asc',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });
    this.fetchProductDepartments(locationId);
    this.fetchProductTypeUnits(companyId);
    this.fetchProductTypes();
    this.fetchProductDataTemplate(companyId);
  }

  fetchProductTypes = () => {
    const { selectedAscDesc, selectedSort, productCodeQuery, productNameQuery } = this.state;
    const { companyId } = this.props;
    this.setState({
      isLoadingProducts: true,
    });
    let filters = '';

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;
    if (productCodeQuery) {
      filters += `&code_contains=${productCodeQuery}`;
    }
    if (productNameQuery) {
      filters += `&name_contains=${productNameQuery}`;
    }
    getProductTypes(companyId, filters)
      .then((resp) => {
        const productTypes = get(resp, 'data.results');
        const count = get(resp, 'data.count');
        const next = get(resp, 'data.next');
        const previous = get(resp, 'data.previous');
        this.setState({
          productTypes,
          count,
          next,
          previous,
          isLoadingProducts: false,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  fetchPaginatedProductTypes = (url) => {
    this.setState({
      isLoadingProducts: true,
    });
    getPaginatedProductTypes(url)
      .then((re) => {
        this.setState({
          productTypes: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingProducts: false,
        });
      });
  }

  fetchProductDepartments = (locationId) => {
    getProductDepartments(locationId).then((resp) => {
      this.setState({
        productDepartments: get(resp, 'data.results'),
      });
    });
  }

  fetchProductTypeUnits = (companyId) => {
    getProductTypeUnits(companyId).then((resp) => {
      this.setState({
        productTypeUnits: get(resp, 'data.results'),
      });
    });
  }

  fetchProductDataTemplate = (companyId) => {
    getProductDataTemplate(companyId).then((resp) => {
      this.setState({
        productDataTemplate: get(resp, 'data.results[0].template'),
      });
    });
  }

  openProductTypeForm = () => {
    this.setState({
      isProductFormOpen: true,
    });
  }

  handleProductTableRowClick = (pType) => {
    const { isReadOnly } = this.state;
    if (!isReadOnly) {
      this.setState({
        selectedProductType: pType,
      }, () => {
        this.openProductTypeForm();
      });
    }
  }

  closeProductFormModal = () => {
    this.setState({
      isProductFormOpen: false,
      selectedProductType: null,
    });
  }

  handleEditProduct = (data) => {
    this.setState({
      isLoadingProducts: true,
    });
    editProductType(data)
      .then((resp) => {
        if (resp.response && resp.response.status >= 200 && resp.response.status < 300) {
          this.setState({
            isProductFormOpen: false,
            isLoadingProducts: false,
            selectedProductType: null,
          }, () => {
            this.fetchProductTypes();
          });
        } else {
          this.setState({
            isLoadingProducts: false,
          });
        }
      });
  }

  handleSaveProductType = (data) => {
    this.setState({
      isLoadingProducts: true,
    });
    saveProductType(data)
      .then((resp) => {
        if (resp.response && resp.response.status >= 200 && resp.response.status < 300) {
          this.setState({
            isProductFormOpen: false,
            isLoadingProducts: false,
            selectedProductType: null,
          }, () => {
            this.fetchProductTypes();
          });
        } else {
          this.setState({
            isLoadingProducts: false,
          });
        }
      });
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchProductTypes();
    });
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchProductTypes();
    }, 600);
  }

  handleShowConfirmationModal = (e, row) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: row });
  };

  handleDeleteProductType = () => {
    this.setState({
      isLoadingProducts: true,
    });
    const row = this.state.deleteData;
    deleteProductType(row.original.id, this.props.companyId).then(() => {
      this.fetchProductTypes();
      this.setState({
        isLoadingProducts: false,
      });
    }).finally(() => this.setState({ showConfirmationDialog: false }));
  };

  render() {
    const { companyId, t } = this.props;

    const {
      productTypes,
      isLoadingProducts,
      isProductFormOpen,
      selectedProductType,
      isReadOnly,
      next,
      previous,
      productDepartments,
      productTypeUnits,
      productDataTemplate,
      productCodeQuery,
      productNameQuery,
      count,
    } = this.state;

    const productTypeOptions = [
      { id: 'bom', name: t('settings.product_types.product_type_options.bom') },
      { id: 'product', name: t('settings.product_types.product_type_options.product') },
      { id: 'assembly', name: t('settings.product_types.product_type_options.assembly') },
      { id: 'part', name: t('settings.product_types.product_type_options.part') },
    ];

    const categoryOptions = [
      { id: 'commercial', name: t('settings.product_types.category_options.commercial') },
      { id: 'assembly', name: t('settings.product_types.category_options.assembly') },
      { id: 'product', name: t('settings.product_types.category_options.product') },
    ];

    const returnTechnologiesFromDepartment = (idToFind, objectList) => ((obj) => obj && obj.technologies)(objectList.find((obj) => obj.id === idToFind));

    const getDepartmentFromId = (idToFind, objectList) => {
      const foundObject = objectList.find((obj) => obj.id === parseInt(idToFind, 10));
      return foundObject ? foundObject.name : null;
    };

    // const getUnitFromId = (idToFind, objectList) => {
    //   const foundObject = objectList.find(obj => obj.id === parseInt(idToFind, 10));
    //   return foundObject ? foundObject.unit : null;
    // };

    const queryOptions = [
      { queryKey: 'productNameQuery', value: productNameQuery, placeholder: t('settings.product_types.table_column_name') },
      { queryKey: 'productCodeQuery', value: productCodeQuery, placeholder: t('settings.product_types.table_column_code') },
    ];

    return (
      <div className="product-types-container">
        <div className="toolbar_area">
          {queryOptions.map((query) => (
            <div key={query.queryKey} className="input_container">
              <input onChange={(e) => this.handleQueryOrFilterChange(query.queryKey, e.target.value)} placeholder={query.placeholder} value={query.value} />
              {query.value && <button
                onClick={() => {
                  this.handleQueryClear(query.queryKey);
                }}
              >&times;</button>}
              <div className="icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </div>
            </div>
          ))}
          <div className="button_wrapper">
            <Button
              onClick={this.openProductTypeForm}
              disabled={isReadOnly}
              type="add"
            >
              {t('settings.product_types.add_new_product_type_button')}
            </Button>
          </div>

        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.product_types.table_column_code')}</span>,
              accessor: 'code',
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_unit')}</span>,
              accessor: 'base_unit',
              Cell: (row) => <span>{row.value || ''}</span>,
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_description')}</span>,
              accessor: 'description',
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_department')}</span>,
              accessor: 'department',
              Cell: (row) => <span>{row.value ? getDepartmentFromId(row.value, productDepartments) : ''}</span>,
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_technology')}</span>,
              accessor: 'technology',
              Cell: (row) => {
                const technologies = returnTechnologiesFromDepartment(row.original.department, productDepartments);
                if (technologies && technologies.length > 0) {
                  const technology = technologies.find((tech) => tech.id === row.value);
                  if (technology) return <span>{technology.name}</span>;
                }
                return '';
              },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_productType')}</span>,
              accessor: 'product_type',
              Cell: (row) => {
                const productTypeName = row.value
                  ? productTypeOptions.find((pt) => pt.id === row.value).name
                  : '';
                return <span>{productTypeName}</span>;
              },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_category')}</span>,
              accessor: 'category',
              Cell: (row) => {
                const productTypeName = row.value
                  ? categoryOptions.find((pt) => pt.id === row.value).name
                  : '';
                return <span>{productTypeName}</span>;
              },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_delete')}</span>,
              width: 200,
              Cell: (row) => (row.original.id ? <Button
                disabled={isReadOnly}
                type="delete"
                onClick={(e) => this.handleShowConfirmationModal(e, row)}
              >{t('settings.product_types.delete_button')}</Button> : '-'),
            },

          ]}
          data={productTypes}
          loading={isLoadingProducts}
          minRows={0}
          noDataText=" "
          sortable
          showPagination={false}
          defaultPageSize={30}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          handleClick={(rowInfo) => this.handleProductTableRowClick(get(rowInfo, 'original'))}
        />
        <div style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedProductTypes} count={count} />
        </div>

        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteProductType}
          type="warning"
        />

        {
          isProductFormOpen && <ProductTypeForm
            isOpen={isProductFormOpen}
            closeModal={this.closeProductFormModal}
            pType={selectedProductType || null}
            companyId={companyId}
            saveProductType={selectedProductType ? this.handleEditProduct : this.handleSaveProductType}
            productDepartments={productDepartments}
            productTypeUnits={productTypeUnits}
            returnTechnologies={returnTechnologiesFromDepartment}
            productTypeOptions={productTypeOptions}
            categoryOptions={categoryOptions}
            productDataTemplate={productDataTemplate}
            loading={isLoadingProducts}
          />
        }
      </div>
    );
  }
}

ProductTypesTab.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ProductTypesTab);
