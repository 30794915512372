import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { defaultDateFormat } from 'shared/constants';
import './styles.scss';

import { getWorkingPlaces, getPaginatedWorkingPlaces, deleteWorkingPlaces } from '../../actions';

import WorkerWorkingPlacesModal from './components/WorkerWorkingPlacesModal';

const WorkerWorkingPlaces = ({ t, companyId, currentUser, workerId, isReadOnly, workerDetails }) => {
  const [tableData, setTableData] = useState({
    data: [],
    isLoading: false,
    previous: null,
    next: null,
    count: 0,
  });
  const [filters, setFilters] = useState({
    selectedSort: 'created_at',
    selectedAscDesc: 'desc',
  });
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({
    showConfirmationDialog: false,
    deleteData: null,
  });

  const fetchWorkingPlaces = async () => {
    setTableData({ ...tableData, isLoading: true });

    let apiFilters = `&worker=${workerId}&limit=15`;

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${filters?.selectedSort}`;

    await getWorkingPlaces(companyId, apiFilters)
      .then((response) => {
        setTableData({
          data: get(response, 'data.results', []),
          isLoading: false,
          previous: get(response, 'data.previous', null),
          next: get(response, 'data.next', null),
          count: get(response, 'data.count', 0),
        });
      })
      .catch((error) => {
        console.error(error);
        setTableData({ ...tableData, isLoading: false });
      });
  };

  const fetchPaginatedWorkersList = async (url) => {
    setTableData({ ...tableData, isLoading: true });
    await getPaginatedWorkingPlaces(url)
      .then((response) => {
        setTableData({
          data: get(response, 'data.results', []),
          isLoading: false,
          previous: get(response, 'data.previous', null),
          next: get(response, 'data.next', null),
          count: get(response, 'data.count', 0),
        });
      })
      .catch((error) => {
        console.error(error);
        setTableData({ ...tableData, isLoading: false });
      });
  };

  useEffect(() => {
    fetchWorkingPlaces();
  }, [filters]);

  const handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'working_place') column = 'working_place__name';

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: column,
    }));
  };

  const handleAddNewWorkingPlace = () => {
    setShowModal(true);
    setInitialData(null);
  };

  const handleEditWorkingPlace = (data) => {
    setShowModal(true);
    setInitialData(data);
  };

  const handleConfirmationModal = (data) => {
    setConfirmationModal({ showConfirmationDialog: true, deleteData: data });
  };

  const handleDeleteWorkingPlace = async () => {
    await deleteWorkingPlaces(companyId, confirmationModal?.deleteData?.id)
      .then(() => { setConfirmationModal({ showConfirmationDialog: false, deleteData: null }); fetchWorkingPlaces(); });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInitialData(null);
  };

  const handleCloseModalAndRefetch = () => {
    handleCloseModal();
    fetchWorkingPlaces();
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.working_places_tab.table_column_working_place')}</span>,
      accessor: 'working_place',
      Cell: (row) => (row?.value?.name ? row.value.name : ''),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.working_places_tab.table_column_start_date')}</span>,
      accessor: 'start_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : ''),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.working_places_tab.table_column_end_date')}</span>,
      accessor: 'end_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : ''),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.working_places_tab.table_column_is_currently')}</span>,
      accessor: 'is_current',
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
      style: {
        cursor: 'default',
      },
      width: 115,
    },
  ];

  return (
    <div className="worker_working_places">

      <div className="worker_working_places__filters_and_actions">
        <div className="worker_working_places__filters_and_actions__actions">
          <Button type="add" onClick={handleAddNewWorkingPlace} disabled={isReadOnly}>
            {t('page_content.workforce_management.worker_details.working_places_tab.button_add_new_place')}
          </Button>
        </div>
      </div>

      <Table
        style={{ userSelect: 'text' }}
        columns={tableColumns}
        data={tableData?.data}
        minRows={0}
        defaultPageSize={15}
        noDataText={t('page_content.workforce_management.worker_details.working_places_tab.table_no_data_placeholder')}
        enableEdit
        enableDelete
        onEdit={handleEditWorkingPlace}
        onDelete={handleConfirmationModal}
        isActionsDisabled={isReadOnly}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'updated_at', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <TableButtons
        previous={tableData?.previous}
        next={tableData?.next}
        fetchFunction={fetchPaginatedWorkersList}
        count={tableData?.count}
      />

      {
        showModal &&
          <WorkerWorkingPlacesModal
            workerId={workerId}
            companyId={companyId}
            showModal={showModal}
            currentUser={currentUser}
            initialData={initialData}
            workerDetails={workerDetails}
            handleCloseModal={handleCloseModal}
            handleCloseModalAndRefetch={handleCloseModalAndRefetch}
          />
      }

      {
        confirmationModal?.showConfirmationDialog &&
          <ConfirmationModal
            itemName={confirmationModal?.deleteData?.working_place?.name || ''}
            showModal={confirmationModal?.showConfirmationDialog}
            handleCloseModal={() => setConfirmationModal({ showConfirmationDialog: false, deleteData: null })}
            handleConfirmModal={handleDeleteWorkingPlace}
            type="warning"
          />
      }

    </div>
  );
};

WorkerWorkingPlaces.propTypes = {
  isReadOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  workerId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  workerDetails: PropTypes.object.isRequired,
};

export default (withTranslation()(WorkerWorkingPlaces));
