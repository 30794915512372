import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

import CompanyValuesTable from './CompanyValuesTable';
import ValueScoresTable from './ValueScoresTable';
import ValueOptionsTable from './ValueOptionsTable';

const CompanyValues = ({ companyId, userHaveFullAccess }) => {
  return (
    <div className="company_values_tab">
      <CompanyValuesTable companyId={companyId} />
      {userHaveFullAccess ? <ValueScoresTable companyId={companyId} /> : null}
      <ValueOptionsTable companyId={companyId} userHaveFullAccess={userHaveFullAccess} />
    </div>
  );
};

CompanyValues.propTypes = {
  // t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default CompanyValues;
