import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';

const ValuesMapper = ({ t, data, accessor, sliceNumber }) => {
  return (
    <div>
        <div><span>{data.map((d) => (!accessor ? d : d[accessor])).slice(0, sliceNumber).join(', ')} </span>
        <Tooltip
          overlay={<div>
                {data?.length > sliceNumber &&
                data?.slice(sliceNumber).map((assetId) => (
                    <div key={assetId}>{!accessor ? assetId : assetId[accessor]}</div>
                ))}
            </div>}
          trigger={data?.length ? ['hover'] : []}
          placement="left"
          overlayClassName="where-filter-tooltip"
        >
        <span style={{ wordBreak: 'word-break' }}>
            {data?.length !== 0 ? (
            <span style={{ textDecoration: 'underline', color: 'blue' }}>
                {data?.length > sliceNumber ? t('shared.values_mapper.and_more', { number: data?.length - sliceNumber }) : ''}
            </span>
            ) : ('-')}
        </span>
        </Tooltip></div>
    </div>
  );
};

ValuesMapper.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  accessor: PropTypes.string,
  sliceNumber: PropTypes.number,
};

ValuesMapper.defaultProps = {
  sliceNumber: 3,
  accessor: '',
};

export default (withTranslation()(ValuesMapper));
