import React, { useState } from 'react';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal, Button } from 'shared';
import { IconInfo } from 'shared/Icons';
import { sendInvitation } from '../../actions';

const UserDetails = ({
  toggleShowUserModal,
  title,
  showUserModal,
  handleSubmit,
  handleChangeData,
  size,
  data,
  roles,
  isEdit,
  t,
  workerCategory,
  companyId,
}) => {
  const [isLoadingInvitation, setIsLoadingInvitation] = useState(false);

  const handleInvitation = async () => {
    setIsLoadingInvitation(true);

    const payload = {
      company_id: companyId,
      email: data?.email,
    };

    await sendInvitation(payload);
    setIsLoadingInvitation(false);
    toggleShowUserModal(false);
  };

  const adminTooltipContent = (
    <h4>
      Warning: a company admin is not affected by role restrictions.
    </h4>
  );

  const first_name_styles = {};
  if (!data.first_name) {
    first_name_styles.borderColor = 'red';
  }

  const last_name_styles = {};
  if (!data.last_name) {
    last_name_styles.borderColor = 'red';
  }

  const email_styles = {};
  if (!data.email) {
    email_styles.borderColor = 'red';
  }

  return (
    <Modal
      isOpen={showUserModal}
      handleClose={() => toggleShowUserModal(false)}
      handleSave={handleSubmit}
      size={size}
      title={title}
    >
      {
        isEdit &&
        <Button
          style={{ float: 'right', margin: '10px 15px 5px 0' }}
          type="success"
          onClick={handleInvitation}
          disabled={!(companyId) || !(data?.email)}
          loading={isLoadingInvitation}
          loadingText=""
        >
          {t('page_content.user_management.users.user_modal.send_invitation')}
        </Button>
      }

      <form className="default-form user-details-form">
        <table>
          <tbody>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.firstname')}
              </td>
              <td className="input">
                <input
                  style={first_name_styles}
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'first_name'); }}
                  value={data.first_name}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.lastname')}
              </td>
              <td className="input">
                <input
                  style={last_name_styles}
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'last_name'); }}
                  value={data.last_name}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.email')}
              </td>
              <td className="input">
                <input
                  style={email_styles}
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'email'); }}
                  value={data.email}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.worker_category')}
              </td>
              <td className="input">
                <Select
                  options={workerCategory}
                  isSearchable={false}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  menuPosition="fixed"
                  onChange={(value) => handleChangeData(value, 'worker_category')}
                  value={(workerCategory && workerCategory.find((wcat) => wcat.id === data.worker_category)) || ''}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.department')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'department'); }}
                  value={data.department}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.position')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'position'); }}
                  value={data.position}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.external_id')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'external_id'); }}
                  value={data.external_id}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.admin')}
              </td>
              <td className="input select">
                <input
                  type="checkbox"
                  onChange={(e) => { handleChangeData(e.target.checked, 'isAdmin'); }}
                  checked={data.isAdmin}
                />&nbsp;&nbsp;
                <Tooltip
                  id="tooltip-is_admin"
                  trigger={['hover']}
                  placement="right"
                  overlay={adminTooltipContent}
                  overlayClassName="where-filter-tooltip"
                >
                  <span
                    aria-describedby="tooltip-is_admin"
                  >
                    <IconInfo
                      color="#2e86de"
                      height="18px"
                      width="20px"
                    />
                  </span>
                </Tooltip>
              </td>
            </tr>
            {
              isEdit &&
              <tr>
                <td className="label">
                  {t('page_content.user_management.users.user_modal.roles')}
                </td>
                <td className="input">
                  <Select
                    isMulti
                    options={roles.map((r) => ({ value: r, label: r.name }))}
                    onChange={(e) => { handleChangeData(e, 'roles'); }}
                    menuPosition="fixed"
                    isClearable={false}
                    value={data.roles ? data.roles.map((r) => ({ value: r, label: r.role ? r.role.name : r.name ? r.name : '' })) : null}
                  />
                </td>
              </tr>
            }
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.active')}
              </td>
              <td className="input">
                <input
                  type="checkbox"
                  onChange={(e) => { handleChangeData(e.target.checked, 'isActive'); }}
                  checked={data.isActive}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.check_in_id')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'check_in_id'); }}
                  value={data.check_in_id}
                  disabled={data && data.check_in_id ? data.check_in_id.value >= 21 : false}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.user_management.users.user_modal.password')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleChangeData(e.target.value, 'password'); }}
                  value={data.password}
                />
              </td>
            </tr>
            {
              !isEdit &&
              <tr>
                <td className="label">
                  {t('page_content.user_management.users.user_modal.send_invitation')}
                </td>
                <td className="input">
                  <input
                    type="checkbox"
                    onChange={(e) => { handleChangeData(e.target.checked, 'invitation'); }}
                    value={data.invitation}
                  />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </form>
    </Modal>
  );
};

UserDetails.propTypes = {
  toggleShowUserModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showUserModal: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeData: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  workerCategory: PropTypes.array.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(UserDetails);
