import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { selectStyles } from 'styles/modules/reactSelect';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { Table, Button, Modal, Notification, ConfirmationModal } from 'shared';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { numberSeparatorFormat, styledBookingStatusOptions } from 'industry/helpers';
import { joinBookings } from '../../../actions';

class OrderBookingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingsJoinLoader: false,
      showConfirmationDialog: false,
      deleteData: null,
    };
  }

  getBookedAtById = (id) => {
    const { bookings } = this.props;

    let booking = null;
    if (bookings && bookings.length) {
      // eslint-disable-next-line eqeqeq
      booking = bookings.find((b) => b.id == id);
    }

    return get(booking, 'booked_at', '');
  }

  handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: row });
  }

  handleConfirmArchive = () => {
    this.props.handleArchiveBooking(this.state.deleteData);
    this.setState({ showConfirmationDialog: false });
  }

  render() {
    const {
      i18n,
      t,
      bookings,
      isLoadingBookings,
      getUserByID,
      handleSelectBooking,
      isReadOnly,
      // isLoadingPostInSAP,
      exportToExcelBookings,
      handleManualEntry,
      handleManualEntryComponents,
      selectedBookingIds,
      selectedBookingsPool,
      joinBookingsModalStatus,
      selectBooking,
      handleBulkAsignModalOpen,
      handleBulkAsignModalClose,
      checkForBookingsJoin,
      handleSelectedBookingsClear,
      // postInSAP,
      loadingSapStatus,
      bookingSapStatus,
      handleSapStatusMessage,
      handleSapStatusColor,
      handleSortingBookings,
      columnOrder,
      selectedBookingType,
      handleFilteringBookings,
    } = this.props;

    const {
      bookingsJoinLoader,
      deleteData,
      showConfirmationDialog,
    } = this.state;

    const bookingTypeFilterOptions = [
      { id: 'confirmation', label: t('page_content.orders.order_details.bookings_tab.select_booking_type_confirmation') },
      { id: 'packaging', label: t('page_content.orders.order_details.bookings_tab.select_booking_type_packaging') },
      { id: 'raw', label: t('page_content.orders.order_details.bookings_tab.select_booking_type_raw_material') },
    ];

    return (
      <React.Fragment>
        <div style={{ display: 'flex', columnGap: '10px', paddingBottom: '15px' }}>
          <Button
            type="export"
            onClick={exportToExcelBookings}
          >
            {t('page_content.orders.order_details.bookings_tab.export_to_excel_button')}
          </Button>

          <Button
            type="dark-blue"
            onClick={handleManualEntry}
            disabled={isReadOnly}
          >
            {t('page_content.orders.order_details.bookings_tab.manual_entry')}
          </Button>
          <Button
            type="dark-blue"
            onClick={handleManualEntryComponents}
            disabled={isReadOnly}
          >
            {t('page_content.orders.order_details.bookings_tab.manual_entry_components')}
          </Button>
          {checkForBookingsJoin && <Button
            type="dark-blue"
            onClick={() => {
              handleBulkAsignModalOpen();
            }}
            disabled={(selectedBookingIds && selectedBookingIds.length < 2) || (selectedBookingsPool && selectedBookingsPool.length < 2)}
          >
            {t('page_content.orders.order_details.bookings_tab.join_bookings')}
          </Button>}

          <Button
            style={{ marginLeft: 'auto' }}
            type={handleSapStatusColor(bookingSapStatus.status)}
            loading={loadingSapStatus}
            loadingText={`SAP Status: ${t('page_content.orders.order_details.bookings_tab.sap_check')}`}
            disabled={loadingSapStatus}
            tooltip={bookingSapStatus.msg || ''}
          >
            {`SAP Status: ${handleSapStatusMessage(bookingSapStatus.status)}`}
          </Button>
        </div>
        <div style={{ display: 'flex', columnGap: '10px', paddingBottom: '15px' }}>
          <Select
            options={bookingTypeFilterOptions}
            getOptionLabel={(line) => line.label}
            getOptionValue={(line) => line.id}
            placeholder={t('page_content.orders.order_details.bookings_tab.select_booking_type_placeholder')}
            isSearchable
            isClearable
            onChange={(e) => handleFilteringBookings(e?.id ?? null)}
            value={(bookingTypeFilterOptions.find((line) => line.id === selectedBookingType)) || ''}
            styles={selectStyles}
          />
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span style={{ display: 'flex', flexDirection: 'column' }}>
                {t('page_content.orders.order_details.bookings_tab.join_bookings')}
                <span>
                {selectedBookingIds?.length ? `(${selectedBookingIds.length})` : ''}
                </span>
                </span>,
              width: 75,
              sortable: false,
              Cell: (row) => (
                <span>
                  <input
                    type="checkbox"
                    value={row.original.id}
                    disabled={get(row, 'original.parent_booking', false)}
                    onChange={(e) => selectBooking(e, row.original)}
                    checked={(selectedBookingIds && selectedBookingIds.length > 0 && selectedBookingIds.includes(row.original.id))}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </span>
              ),
              show: checkForBookingsJoin,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.merged_bookings')}</span>,
              width: 75,
              sortable: false,
              Cell: (row) => (
                <span>
                  {
                    get(row, 'original.parent_booking', '') ?
                      <p>{t('page_content.orders.order_details.bookings_tab.merged_into')}: {moment(this.getBookedAtById(get(row, 'original.parent_booking', ''))).format('DD.MM - HH:mm:ss')}</p> :
                      get(row, 'original.merged_booking', false) &&
                      <p>{t('page_content.orders.order_details.bookings_tab.merged_booking')}</p>
                  }
                </span>
              ),
              show: checkForBookingsJoin,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_time')}</span>,
              accessor: 'booked_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_line')}</span>,
              accessor: 'production_line',
              sortable: false,
              Cell: (row) => (<div>
                <span style={{ display: 'block' }}>{row && row.value && row.value.name ? row.value.name : '-'}</span>
                <b style={{ display: 'block' }}>{get(row, 'original.is_manual_booking', false) ?
                  t('page_content.orders.order_details.bookings_tab.manual_booking') : null}</b>
              </div>),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_batch')}</span>,
              accessor: 'batch',
              Cell: (row) => <span>{row && row.value ? row.value : '-'}</span>,
              show: checkForBookingsJoin,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_shift')}</span>,
              accessor: 'shift',
              Cell: (row) => <span>{row && row.value ? row.value : '-'}</span>,
              show: checkForBookingsJoin,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_total')}</span>,
              accessor: 'product_quantity_total',
              Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_counted')}</span>,
              accessor: 'product_quantity_plc',
              Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_booked')}</span>,
              accessor: 'product_quantity_booked',
              Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_remaining')}</span>,
              accessor: 'product_quantity_remaining',
              Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_booking_type')}</span>,
              accessor: 'booking_type',
              Cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={styledBookingStatusOptions(row?.value)}>{row?.value ?
                    t([`page_content.orders.booking_statuses.${row?.value}`]) : t('page_content.orders.booking_statuses.no_status')}</span>
                </div>
              ),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_booking_final')}</span>,
              accessor: 'booking_final',
              Cell: (row) => (row?.value ?
              <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
            },

            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_confirmation')}</span>,
              accessor: 'booked_confirmation',
              Cell: (row) => (row?.value ?
                <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_confirmation_time')}</span>,
              accessor: 'booked_confirmation_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_confirmation_user')}</span>,
              accessor: 'booked_confirmation_user',
              Cell: (row) => <span>{row && row.value ? getUserByID(row.value, ['first_name', 'last_name']) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_goods')}</span>,
              accessor: 'booked_goods',
              Cell: (row) => (row?.value ?
                <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_goods_time')}</span>,
              accessor: 'booked_goods_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_goods_user')}</span>,
              accessor: 'booked_goods_user',
              Cell: (row) => <span>{row && row.value ? getUserByID(row.value, ['first_name', 'last_name']) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.archive')}</span>,
              sortable: false,
              Cell: (row) => (<Button
                type="danger"
                onClick={(e) => this.handleShowConfirmationModal(row, e)}
                disabled={get(row, 'original.merged_booking', false) ? get(row, 'original.booked_goods', false) : get(row, 'original.booked_confirmation', false) || get(row, 'original.booked_goods', false)}
              >
                {t('page_content.orders.order_details.bookings_tab.archive')}
              </Button>),
              width: 120,
              show: checkForBookingsJoin,
            },
          ]}
          data={bookings || []}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          sortable
          loading={isLoadingBookings}
          defaultSorted={[{ id: columnOrder.column, desc: columnOrder.asc === 'desc' }]}
          onSortedChange={(newSorted) => { handleSortingBookings(newSorted[0]); }}
          handleClick={(row) => handleSelectBooking(row.original)}
        />
        <Modal
          isOpen={joinBookingsModalStatus}
          handleClose={() => {
            handleBulkAsignModalClose();
            this.setState({ bookingsJoinMessage: '' });
          }}
          size={modalSizes.full}
          title={t('page_content.orders.order_details.bookings_tab.join_bookings')}
        >
          <div className="bookings_join_modal_wrapper">
            <div>{`${t('page_content.orders.order_details.bookings_tab.modal_header')}: ${selectedBookingIds.length}`}</div>
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_time')}</span>,
                  accessor: 'booked_at',
                  Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_line')}</span>,
                  accessor: 'production_line',
                  Cell: (row) => <span>{row && row.value && row.value.name ? row.value.name : '-'}</span>,
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_total')}</span>,
                  accessor: 'product_quantity_total',
                  Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_counted')}</span>,
                  accessor: 'product_quantity_plc',
                  Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_booked')}</span>,
                  accessor: 'product_quantity_booked',
                  Cell: (row) => <span>{row && row.value ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : '-'}</span>,
                },
              ]}
              data={selectedBookingsPool}
              loading={bookingsJoinLoader}
              minRows={0}
              defaultPageSize={30}
              noDataText=" "
              showPagination
              sortable={false}
              nextText={t('pagination_table.next')}
              previousText={t('pagination_table.previous')}
            />
            <div className="bookings_button_area">
              <Button
                disabled={bookingsJoinLoader}
                onClick={() => {
                  this.setState({ bookingsJoinLoader: true });
                  joinBookings({ booking_ids: selectedBookingIds, merge_type: 'all' })
                    .then((re) => {
                      if (re.status >= 200 && re.status < 300) {
                        this.setState({
                          bookingsJoinLoader: false,
                        }, () => {
                          Notification('success', t('page_content.orders.order_details.bookings_tab.bookings_joined_message'));
                          handleSelectedBookingsClear();
                          handleBulkAsignModalClose();
                        });
                      }
                    });
                }}
              >{t('page_content.orders.order_details.bookings_tab.merge_bom_and_quantities')}</Button>

              <Button
                disabled={bookingsJoinLoader}
                onClick={() => {
                  this.setState({ bookingsJoinLoader: true });
                  joinBookings({ booking_ids: selectedBookingIds, merge_type: 'bom_only' })
                    .then((re) => {
                      if (re.status >= 200 && re.status < 300) {
                        this.setState({
                          bookingsJoinLoader: false,
                        }, () => {
                          Notification('success', t('page_content.orders.order_details.bookings_tab.bookings_joined_message'));
                          handleSelectedBookingsClear();
                          handleBulkAsignModalClose();
                        });
                      }
                    });
                }}
              >{t('page_content.orders.order_details.bookings_tab.merge_only_bom')}</Button>
            </div>
          </div>
        </Modal>
        <ConfirmationModal
          customTitle={`${t('page_content.orders.order_details.bookings_tab.archive_message')} ${moment(deleteData?.original?.booked_at).format(defaultDateTimeFormat)}?`}
          showModal={showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleConfirmArchive}
          type="warning"
        />
      </React.Fragment>
    );
  }
}

OrderBookingsTable.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  bookings: PropTypes.array.isRequired,
  isLoadingBookings: PropTypes.bool.isRequired,
  getUserByID: PropTypes.func.isRequired,
  handleSelectBooking: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  // isLoadingPostInSAP: PropTypes.bool.isRequired,
  exportToExcelBookings: PropTypes.func.isRequired,
  handleManualEntry: PropTypes.func.isRequired,
  handleManualEntryComponents: PropTypes.func.isRequired,
  selectedBookingIds: PropTypes.array.isRequired,
  selectedBookingsPool: PropTypes.array.isRequired,
  joinBookingsModalStatus: PropTypes.bool.isRequired,
  selectBooking: PropTypes.func.isRequired,
  handleBulkAsignModalOpen: PropTypes.func.isRequired,
  handleBulkAsignModalClose: PropTypes.func.isRequired,
  checkForBookingsJoin: PropTypes.bool.isRequired,
  handleSelectedBookingsClear: PropTypes.func.isRequired,
  handleArchiveBooking: PropTypes.func.isRequired,
  bookingSapStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  loadingSapStatus: PropTypes.bool.isRequired,
  handleSapStatusMessage: PropTypes.func.isRequired,
  handleSapStatusColor: PropTypes.func.isRequired,
  handleSortingBookings: PropTypes.func,
  columnOrder: PropTypes.object,
  selectedBookingType: PropTypes.string,
  handleFilteringBookings: PropTypes.func.isRequired,
  // postInSAP: PropTypes.func.isRequired,
};

export default withTranslation()(OrderBookingsTable);
