import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import api from 'helpers/api';
import { get } from 'lodash';
import { connect } from 'react-redux';
// import { CSSTransition } from 'react-transition-group';
import { IconSearch, IconRemove } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import { Button, Table, Notification, TableButtons, ConfirmationModal } from 'shared';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import { AssetForm } from './components/';
import { filterAssets } from './helpers';
import { fetchAssets, getAssetModel, getAssetTypes, getAssetGroups, getAssetGroupTypes, createAssetType, createAssetGroup, createAsset, updateAsset } from './actions';
import './styles.scss';

class IndustryLocationAssetList extends Component {
  constructor(props) {
    super(props);
    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    // Used for reading saved User soring and filtering
    // let selectedSort = 'name';
    // let selectedAscDesc = 'desc';

    // const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    // if (sortingAndFiltering.assetList) {
    //   const sAndF = sortingAndFiltering.assetList;
    //   selectedSort = sAndF.selectedSort || selectedSort;
    //   selectedAscDesc = sAndF.selectedAscDesc || selectedAscDesc;
    // }

    this.state = {
      activeQuery: '',
      assets: [],
      assetModels: [],
      assetTypes: [],
      assetGroups: [],
      assetStatuses: [],
      assetGroupTypes: [],
      isLoadingAssets: true,
      showDetails: false,
      showAssetForm: false,
      isReadOnly,
      selectedAscDesc: 'desc',
      selectedSort: 'name',
      count: null,
      selectedAssetObject: [],
      next: null,
      previous: null,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Assets');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.getAssets();
    this.fetchAssetModels();
    this.fetchAssetTypes();
    this.fetchAssetGroups();
    this.fetchAssetStatuses();
    this.fetchAssetGroupTypes();
  }

  getAssets = () => {
    const { companyId, locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    this.setState({
      isLoadingAssets: true,
    });

    const url = `/api/v1/industry/assets/?location=${locationId}&company=${companyId}&fat=1&order_by=${selectedAscDesc === 'desc' ? '-' : ''}${selectedSort}`;
    fetchAssets(url)
      .then((res) => {
        this.setState({
          count: get(res, 'data.count'),
          assets: get(res, 'data.results'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingAssets: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoadingAssets: false });
      });
  }

  fetchAssetModels = () => {
    const { companyId } = this.props;
    getAssetModel(companyId)
      .then((res) => {
        this.setState({
          assetModels: get(res, 'data.results'),
        });
      });
  }

  fetchAssetTypes = () => {
    const { companyId, locationId } = this.props;
    getAssetTypes(companyId, locationId)
      .then((res) => {
        this.setState({
          assetTypes: get(res, 'data.results'),
        });
      });
  }

  fetchAssetGroups = () => {
    const { companyId, locationId } = this.props;
    getAssetGroups(locationId, companyId)
      .then((res) => {
        this.setState({
          assetGroups: get(res, 'data.results'),
        });
      });
  }

  fetchAssetStatuses = () => {
    api.get('/api/v1/industry/all_statuses')
      .then((res) => {
        if (res && res.data && res.data.Asset && res.data.Asset.label_type) {
          this.setState({
            assetStatuses: res.data.Asset.label_type,
          });
        }
      });
  }

  fetchAssetGroupTypes = () => {
    const { companyId } = this.props;
    getAssetGroupTypes(companyId)
      .then((res) => {
        this.setState({
          assetGroupTypes: get(res, 'data.results'),
        });
      });
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { count } = this.state;

    api.get(`/api/v1/industry/assets/?location=${locationId}&company=${companyId}&fat=1&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'asset_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  saveSortingAndFiltering = () => {
    const {
      selectedAscDesc,
      selectedSort,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.assetList = {
        selectedAscDesc: selectedAscDesc === 'desc' ? 'desc' : 'asc',
        selectedSort,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
    }
  }

  handleClear = () => {
    this.setState({
      activeQuery: '',
    }, () => {
    });
  }

  handleSubmit = (e) => {
    this.setState({
      activeQuery: e,
    }, () => {
    });
  }

  handleShowConfirmationModal = (asset, e) => {
    e.stopPropagation();
    this.setState({ deleteData: asset, showConfirmationDialog: true });
  }

  handleDeleteAsset = () => {
    const id = this.state.deleteData.original.id;
    return api
      .delete(`/api/v1/industry/assets/${id}/?fat=1`)
      .then(() => {
        this.getAssets();
        Notification('success', 'Delete successful', 'Asset was successfuly deleted.');
      })
      .catch(() => {
        Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  handleSorting = (sortData) => {
    const { locationId, companyId } = this.props;
    const currentUrl = `/api/v1/industry/assets/?location=${locationId}&company=${companyId}&fat=1`;
    this.setState({ isLoadingAssets: true });
    const column = sortData.id;
    const isDescending = sortData.desc;
    let sortKey = column;

    if (column === 'model' || column === 'type') {
      sortKey = `asset_${column}`;
    }

    if (column === 'metrics_count') {
      sortKey = 'num_metrics';
    }

    this.setState({
      selectedSort: sortKey,
      selectedAscDesc: isDescending ? 'desc' : 'asc',
    });

    const newUrl = `${currentUrl}&order_by=${isDescending ? '-' : ''}${sortKey}`;
    api.get(newUrl)
      .then((res) => {
        this.setState({
          assets: get(res, 'data.results'),
          isLoadingAssets: false,
        });
        this.saveSortingAndFiltering();
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoadingAssets: false });
      });
  }

  handleAssetsTableRowClick = (assetObject) => {
    this.setState({
      selectedAssetObject: assetObject.original,
    }, () => {
      this.openAssetForm();
    });
  }

  openAssetForm = () => {
    this.setState({
      showAssetForm: true,
    });
  }

  closeAssetForm = () => {
    this.setState({
      showAssetForm: false,
      selectedAssetObject: {},
    });
  }

  handleUpdateAsset = (assetId, assetData) => {
    this.setState({
      isLoadingAssets: true,
    });
    updateAsset(assetId, assetData)
      .then(() => {
        this.setState({
          isLoadingAssets: false,
        }, () => {
          this.closeAssetForm();
          this.getAssets();
          this.fetchAssetGroups();
        });
      });
  }

  handleCreateAsset = (assetData) => {
    const { companyId } = this.props;
    this.setState({
      isLoadingAssets: true,
    });
    createAsset(companyId, assetData)
      .then(() => {
        this.setState({
          isLoadingAssets: false,
        }, () => {
          this.closeAssetForm();
          this.getAssets();
        });
      });
  }

  handleCreateAssetType = (assetData) => {
    const { companyId } = this.props;
    this.setState({
      isLoadingAssets: true,
    });
    const modifiedObject = { ...assetData };
    createAssetType(assetData.asset_type)
      .then((re) => {
        if (!(re && re.status >= 200) && !(re && re.status < 300)) {
          modifiedObject.asset_type = re?.response?.data?.id;
          this.fetchAssetTypes();
          createAsset(companyId, modifiedObject).then(() => {
            this.setState({
              isLoadingAssets: false,
            }, () => {
              this.closeAssetForm();
              this.getAssets();
            });
          });
        }
      });
  }

  handleCreateAssetGroup = (assetData) => {
    const { companyId } = this.props;
    this.setState({
      isLoadingAssets: true,
    });
    const modifiedObject = { ...assetData };
    createAssetGroup(assetData.asset_group)
      .then((re) => {
        if (!(re && re.status >= 200) && !(re && re.status < 300)) {
          modifiedObject.asset_group = re?.response?.data?.id;
          this.fetchAssetGroups();
          createAsset(companyId, modifiedObject).then(() => {
            this.setState({
              isLoadingAssets: false,
            }, () => {
              this.closeAssetForm();
              this.getAssets();
            });
          });
        }
      });
  }

  createAssetWithNewGroupAndType = (assetData) => {
    const { companyId } = this.props;
    this.setState({
      isLoadingAssets: true,
    });
    const modifiedObject = { ...assetData };
    createAssetGroup(assetData.asset_group)
      .then((re) => {
        if (!(re && re.status >= 200) && !(re && re.status < 300)) {
          modifiedObject.asset_group = re?.response?.data?.id;
          this.fetchAssetGroups();
          createAssetType(assetData.asset_type)
            .then((r) => {
              if (!(r && r.status >= 200) && !(r && r.status < 300)) {
                modifiedObject.asset_type = r?.response?.data?.id;
                this.fetchAssetTypes();
                createAsset(companyId, modifiedObject).then(() => {
                  this.setState({
                    isLoadingAssets: false,
                  }, () => {
                    this.closeAssetForm();
                    this.getAssets();
                  });
                });
              }
            });
        }
      });
  }

  fetchData = (url) => {
    this.setState({ isLoadingAssets: true });
    fetchAssets(url)
      .then((res) => {
        this.setState({
          count: get(res, 'data.count'),
          assets: get(res, 'data.results'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingAssets: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoadingAssets: false });
      });
  };

  render() {
    const { locationId, companyId, currentUser, t } = this.props;

    const {
      activeQuery,
      assets,
      isLoadingAssets,
      showAssetForm,
      showDetails,
      isReadOnly,
      selectedAssetObject,
      assetTypes,
      assetModels,
      assetGroups,
      assetStatuses,
      assetGroupTypes,
      next,
      previous,
      count,
    } = this.state;

    const filteredAssets = filterAssets(assets, activeQuery);
    if (filteredAssets !== undefined && assetModels && assetModels.length > 0 && assetTypes && assetTypes.length > 0) {
      filteredAssets.forEach((a) => {
        let metricsCount = 0;
        if (a.interfaces && a.interfaces.length) {
          a.interfaces.forEach((i) => {
            metricsCount += i.metrics ? i.metrics.length : 0;
          });
        }
        a.metricsCount = metricsCount;
        if (assetModels && assetModels.length > 0 && a.asset_model !== null && a.asset_model !== undefined) {
          a.model = assetModels?.find((m) => m.id === a?.asset_model).model;
        }
        if (assetTypes && assetTypes.length > 0 && a.asset_type !== null && a.asset_type !== undefined) {
          a.type = assetTypes?.find((m) => m.id === a?.asset_type).code;
        }
      });
    }

    return (
      <div className="industry-location-drone-list fullscreen">
        <div className="assets_toolbar_area">
          <div className="assets_input_container">
            <input onChange={(e) => this.handleSubmit(e.target.value)} placeholder={t('page_content.assets.search_placeholder')} value={activeQuery} />
            {activeQuery && <button
              onClick={() => {
                this.handleClear();
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="buttons_wrapper">
            <Button
              onClick={this.openAssetForm}
              title="Add asset"
              disabled={isReadOnly}
              type="add"
            >
              {t('page_content.assets.add_asset_button')}
            </Button>
            <Button
              type="export"
              style={{ marginLeft: '10px' }}
              onClick={this.exportToExcel}
            >{t('page_content.assets.export_to_excel_button')}</Button>
          </div>
        </div>
        <main>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.assets.table_column_name')}</span>,
                accessor: 'name',
                maxWidth: 200,
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_serial_number')}</span>,
                accessor: 'serial_number',
                maxWidth: 200,
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_inventory_id')}</span>,
                accessor: 'external_id',
                maxWidth: 200,
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_type')}</span>,
                accessor: 'type',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_model')}</span>,
                accessor: 'model',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_commissioning_date')}</span>,
                accessor: 'commissioning_date',
                Cell: (row) => moment(row.value).format(defaultDateFormat),
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_number_of_metrics')}</span>,
                accessor: 'metrics_count',
              },
              {
                show: currentUser && currentUser.is_admin ? !!currentUser.is_admin : false,
                Header: () => <span>{t('page_content.assets.table_column_delete_asset')}</span>,
                width: 120,
                Cell: (asset) => <div style={{ margin: 'auto' }}><Button size="small" type="delete" className="remove-btn" onClick={(e) => this.handleShowConfirmationModal(asset, e)}><IconRemove width="13px" height="13px" /></Button></div>,
              },
            ]}
            data={filteredAssets}
            defaultPageSize={100}
            handleClick={(rowInfo) => !isReadOnly && this.handleAssetsTableRowClick(rowInfo)}
            loading={isLoadingAssets}
            minRows={0}
            noDataText=" "
            selectedRow={showDetails.id}
            showPagination={false}
            sortable
            defaultSorted={[{ id: 'name', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </main>
        {
          showAssetForm &&
          <AssetForm
            isReadOnly={isReadOnly}
            isOpen={showAssetForm}
            onClose={this.closeAssetForm}
            companyId={companyId}
            locationId={locationId}
            assetObject={selectedAssetObject}
            assetTypesDropdown={assetTypes}
            assetModelsDropdown={assetModels}
            assetGroupsDropdown={assetGroups}
            assetGroupTypesDropdown={assetGroupTypes}
            stickerDesignDropdown={assetStatuses}
            updateAsset={this.handleUpdateAsset}
            createAsset={this.handleCreateAsset}
            createAssetType={this.handleCreateAssetType}
            createAssetGroup={this.handleCreateAssetGroup}
            createAssetWithNewGroupAndType={this.createAssetWithNewGroupAndType}
          />
        }
         <ConfirmationModal
           itemName={this.state.deleteData?.original?.name}
           showModal={this.state.showConfirmationDialog}
           handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
           handleConfirmModal={this.handleDeleteAsset}
           type="warning"
         />
      </div>
    );
  }
}

IndustryLocationAssetList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryLocationAssetList));
